import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import queryString from "query-string";
import logo from "../../assets/img/spacia/web/spacia_logo.png";
import {Container, Form, Row} from "react-bootstrap";
import bg from "../../assets/img/spacia/web/Background.png";
import apiService from "../../services/apiService";
import { Constants } from '../../utils/constants';
import {FaRegEye as FaEye, FaRegEyeSlash as FaEyeSlash} from "react-icons/fa";

const ConfirmInvite = () => {
	const [otp, setOtp] = useState(null);
	const [username, setUsername] = useState(null);
	const [password, setPassword] = useState(null);
	const [confirmPassword, setConfirmPassword] = useState(null);
	const [firstname, setFirstname] = useState(null);
	const [lastname, setLastname] = useState(null);
	const [packageId, setPackageId] = useState(0);

	const [confirmPasswordValidationError, setConfirmPasswordValidationError] = useState('');
	const [isEmptyFirstname, setIsEmptyFirstname] = useState(false);
	const [isEmptyLastname, setIsEmptyLastname] = useState(false);
	const [isEmptyConfirmationCode, setIsEmptyConfirmationCode] = useState(false);
	const [isEmptyPassword, setIsEmptyPassword] = useState(false);
	const [isInvalidConfirmPassword, setIsInvalidConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const location = useLocation();
	const search = queryString.parse(location.search);
	const params = search ? search.payload : "";
	const payload = JSON.parse(atob(params));

	console.log('confirm invite payload:', payload);

	useEffect(() => {
		if (payload.username) {
			setUsername(payload.username);
		}

		if (payload.packagePlan) {
			setPackageId(payload.packagePlan);
		}
	}, []);

	useEffect(() => {
		console.log('package plan id:', packageId);
	}, [packageId])

	const bgImage = {
		width: "100vw",
		height: "100vh",
		background: "gray",
		backgroundAttachment: "fixed",
		backgroundPosition: "center",
		backgroundSize: "cover",
		position: "relative",
		// boxShadow: '0 4 38 rgba(0, 0, 0, 0.06)',
		backgroundImage: `url(${bg})`,
	};
	const formCard = {
		width: "35%",
		position: "absolute",
		right: "10vw",
		top: "10vh",
		backgroundColor: "white",
		padding: 30,
		// paddingBottom:50,
		// boxShadow: '0px 4px 38px rgba(0, 0, 0, 0.06);',
		boxShadow: "0px 4px 38px #000000, 6%",
	};

	const logopos = {
		position: "relative",
		left: "5vw",
		top: "5vh",
	};

	useEffect(() => {
		if (hasText(firstname)) {
			setIsEmptyFirstname(false);
		}
		if (hasText(lastname)) {
			setIsEmptyLastname(false);
		}
		if (hasText(otp)) {
			setIsEmptyConfirmationCode(false);
		}
		if (hasText(password)) {
			setIsEmptyPassword(false);
		}
		if (hasText(confirmPassword)) {
			setIsInvalidConfirmPassword(false);
		}
		if (password && !confirmPassword) {
			setIsInvalidConfirmPassword(false);
		}
	}, [firstname, lastname, otp, password, confirmPassword]);

	const areFieldsOkay = () => {
		const requiredFields = [firstname, lastname, otp, password, confirmPassword];
		// is any of the fields empty
		const anyEmpty = requiredFields.some(field => !field);
		if (anyEmpty) {
			if (!hasText(firstname)) {
				setIsEmptyFirstname(true);
			}
			if (!hasText(lastname)) {
				setIsEmptyLastname(true);
			}
			if (!hasText(otp)) {
				setIsEmptyConfirmationCode(true);
			}
			if (!hasText(password)) {
				setIsEmptyPassword(true);
			}
			if (!hasText(confirmPassword)) {
				setIsInvalidConfirmPassword(true);
				setConfirmPasswordValidationError('Confirm password is required');
			} else if (password && confirmPassword && confirmPassword !== password) {
				setIsInvalidConfirmPassword(true);
				setConfirmPasswordValidationError('Passwords do not match');
			}

			return false;
		} else {
			return true;
		}
	}

	useEffect(() => {
		if (password && confirmPassword && confirmPassword !== password) {
			setIsInvalidConfirmPassword(true);
			setConfirmPasswordValidationError('Passwords do not match');
		}
	}, [confirmPassword])

	const history = useHistory();
	let localUrl = `${Constants.BASE_URL}/users/api/v1/users/otp`;
	const handleSubmit = (e) => {
		e.preventDefault();

		if (!areFieldsOkay()) return;

		apiService.verifyOTP({
			username,
			otp,
			firstName: firstname,
			lastName: lastname,
			password,
			confirmPassword,
			packageId
		})
			.then((res) => {
				console.log(res);
				history.push("/signin");
			}).catch((err) => {
				console.log(err);
				history.push("/signin");
			});
		console.log(localUrl);
	};

	// const resendOtp = (e) => {
	// 	localUrl = `${Constants.BASE_URL}/users/api/v1/otp`;

	// 	apiService.resendOTP(username)
	// 		.then((res) => console.log(res));
	// };

	function hasText(text) {
		return text != null && text !== '';
	}

	const togglePasswordVisibility = e => {
    e.preventDefault();

    setShowPassword(!showPassword);
  }

  const toggleConfirmPasswordVisibility = e => {
    e.preventDefault();

    setShowConfirmPassword(!showConfirmPassword);
  }

	return (
		<Row style={bgImage}>
			{/* <div > */}
			<div style={logopos}>
				<img src={logo} alt="logo" />
			</div>
			<div style={formCard}>
				<Container>
					<h5>Account Confirmation</h5>
					<br />
					<br />

					<form onSubmit={handleSubmit}>
						<div className="form-group">
							<label htmlFor="email">Email</label>
							<input
								type="email"
								className="form-control"
								readOnly
								name="username"
								id="email"
								aria-describedby="emailHelpId"
								value={username}
							/>
						</div>
						<br />
						<div className="form-group">
							<label htmlFor="otp">Confirmation Code</label>
							<input
								type="text"
								className={ isEmptyConfirmationCode ? "form-control is-invalid" : "form-control" }
								name="otp"
								id="otp"
								placeholder="Enter Confirmation Code"
								value={otp}
								onChange={(e) => {
									setOtp(e.target.value);
								}}
							/>
							<div className="invalid-feedback">Please enter confirmation code</div>
						</div>
						<br />
						<div className="form-group">
							<label htmlFor="firstname">First name</label>
							<input
								type="text"
								className={ isEmptyFirstname ? "form-control is-invalid" : "form-control" }
								name="firstName"
								id="firstname"
								aria-describedby="firstName"
								value={firstname}
								onChange={(e) => {
									setFirstname(e.target.value);
								}}
							/>
							<div className="invalid-feedback">Please enter first name</div>
						</div>
						<br />
						<div className="form-group">
							<label htmlFor="lastname">Last name</label>
							<input
								type="text"
								className={ isEmptyLastname ? "form-control is-invalid" : "form-control" }
								name=""
								id="lastname"
								aria-describedby="lastName"
								value={lastname}
								onChange={(e) => {
									setLastname(e.target.value);
								}}
							/>
							<div className="invalid-feedback">Please enter last name</div>
						</div>
						<br />
            <Form.Group controlId="formGroupPassword">
              <Form.Label>Password</Form.Label>
              <div className="input-group">
                <Form.Control type={showPassword ? "text" : "password"}
                              value={password} className={ isEmptyPassword ? "form-control is-invalid" : "form-control" }
                              onChange={(e) => setPassword(e.target.value)}/>
                <div className="input-group-addon">
                  <a onClick={togglePasswordVisibility}><i>{showPassword ? <FaEye /> : <FaEyeSlash />}</i></a>
                  {/*<a><i><FaEye/></i></a>*/}
                </div>
                <div className="invalid-feedback">Password is required</div>
              </div>
            </Form.Group>
						<br />
            <Form.Group controlId="formGroupPassword">
              <Form.Label> Confirm Password</Form.Label>
              <div className="input-group">
                <Form.Control type={showConfirmPassword ? "text" : "password"} className={ isInvalidConfirmPassword ? "form-control is-invalid" : "form-control" }
                              value={confirmPassword}
                              onChange={(e) => setConfirmPassword(e.target.value)}/>
                <div className="input-group-addon">
                  <a onClick={toggleConfirmPasswordVisibility}><i>{showConfirmPassword ? <FaEye /> : <FaEyeSlash />}</i></a>
                  {/*<a><i><FaEye/></i></a>*/}
                </div>
                {/* confirm password is required and must also match with the password */}
                <div className="invalid-feedback">{confirmPasswordValidationError}</div>
              </div>
            </Form.Group>
						<br />
						<br />
						<div style={{margin: "auto", textAlign: "center"}}>
							<button className="button" style={{width: "100%"}}>
								Submit
							</button>
						</div>
					</form>
				</Container>
			</div>
			{/* </div> */}
		</Row>
	);
};

export default ConfirmInvite;
