import React, {useEffect, useState} from 'react'
import {Tab, Row} from 'react-bootstrap'

const StayAmenities = ({cancelation, changeCancelation,pool, changePool, airportShuttle, changeairportShuttle, spa, changeSpa, nonSmokingRooms, changenonSmokingRooms, restaurant, changeRestaurant, freeWifi, changefreeWifi, coffeeMaker, changecoffeeMaker, bar, changeBar, lifts, changeLifts, food, changeFood, parking, changeParking, rooms, changeROoms, washingMachine, changewashingMachine, gym, changeGym, garbage, changeGarbage, laundry, changeLaundry, housekeeping, changeHousekeeping, cctv, changeCctv, hotWater, changehotWater, bills, changeBills, cookers, changeCookers, security, changeSecurity, generator, changeGenerator, dstv, changeDstv, fence, changeFence, frontView, changefrontView,  }) => {


  // const [access, setaccess] = useState("yes")
  // const [cancelation, setcancelation] = useState()
  // const [pool, setpool] = useState()
  // const [airportshuttle, setairportshuttle] = useState()
  // const [spa, setspa] = useState()
  // const [nonsmokingrooms, setnonsmokingrooms] = useState()
  // const [frontview, setfrontview] = useState()
  // const [restaurant, setrestaurant] = useState()
  // const [freewifi, setfreewifi] = useState()
  // const [coffeemaker, setcoffeemaker] = useState()
  // const [bar, setbar] = useState()
  // const [lifts, setlifts] = useState()
  // const [food, setfood] = useState()
  // const [parking, setparking] = useState()
  // const [rooms, setrooms] = useState()
  // const [washingmachine, setwashingmachine] = useState()
  // const [gym, setgym] = useState()
  // const [garbage, setgarbage] = useState()
  // const [laundry, setlaundry] = useState()
  // const [housekeeping, sethousekeeping] = useState()
  // const [cctv, setcctv] = useState()
  // const [hotwater, sethotwater] = useState()
  // const [bills, setbills] = useState()
  // const [cookers, setcookers] = useState()
  // const [security, setsecurity] = useState()
  // const [generator, setgenerator] = useState()
  // const [dstv, setdstv] = useState()
  // const [fence, setfence] = useState()


  useEffect(() => {
    console.log(`Inside the 'StayAmenities' component. washingMachine value is ${washingMachine}`);
  }, [])

  const stayamenities = [];
  const findAmenities = () => {
    if (cancelation) {
      stayamenities.push("CancelationFlexibility");
      console.log(stayamenities);
    }
    if (pool) {
      stayamenities.push("Pool");
      console.log(stayamenities);
    }
    if (airportShuttle) {
      stayamenities.push("AirportShuttle");
      console.log(stayamenities);
    }
    if (spa) {
      stayamenities.push("Spa");
      console.log(stayamenities);
    }
    if (nonSmokingRooms) {
      stayamenities.push("NonSmokingRooms");
      console.log(stayamenities);
    }
    if (frontView) {
      stayamenities.push("CityView");
      console.log(stayamenities);
    }
    if (restaurant) {
      stayamenities.push("Restaurant");
      console.log(stayamenities);
    }
    if (freeWifi) {
      stayamenities.push("FreeWiFi");
      console.log(stayamenities);
    }
    if (rooms) {
      stayamenities.push("MeetingRooms");
      console.log(stayamenities);
    }
    if (coffeeMaker) {
      stayamenities.push("CoffeeMakerInRoom");
      console.log(stayamenities);
    }
    if (bar) {
      stayamenities.push("Bar");
      console.log(stayamenities);
    }
    if (garbage) {
      stayamenities.push("GarbageCollection");
      console.log(stayamenities);
    }
    if (laundry) {
      stayamenities.push("LaundryServices");
      console.log(stayamenities);
    }
    if (housekeeping) {
      stayamenities.push("Housekeeping");
      console.log(stayamenities);
    }
    if (cctv) {
      stayamenities.push("CCTV");
      console.log(stayamenities);
    }
    if (hotWater) {
      stayamenities.push("HotWater");
      console.log(stayamenities);
    }
    if (bills) {
      stayamenities.push("WaterAndElectricBills");
      console.log(stayamenities);
    }
    if (cookers) {
      stayamenities.push("Cookers");
      console.log(stayamenities);
    }
    if (security) {
      stayamenities.push("24HourSecurity");
      console.log(stayamenities);
    }
    if (generator) {
      stayamenities.push("StandbyBackupGenerator");
      console.log(stayamenities);
    }
    if (dstv) {
      stayamenities.push("TV");
      console.log(stayamenities);
    }
    if (fence) {
      stayamenities.push("ElectricFence");
      console.log(stayamenities);
    }
    else{
      console.log("No Amenities Found")
    }
  }




    const label = {
        fontWeight: "300",
        marginBottom: 10,
      };
    
    return (
        <>
            <Row>
                <div className="col-md-3">
                  <div className="form-check" style={{ paddingLeft: "35px" }}>
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={cancelation}
                        onChange={changeCancelation}
                        aria-label="Text for screen reader"
                      />{" "}
                     Cancelation Flexibility
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-check" style={{ paddingLeft: "35px" }}>
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={pool}
                        onChange={changePool}
                        aria-label="Text for screen reader"
                      />{" "}
                      Swimming Pools
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-check" style={{ paddingLeft: "35px" }}>
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={airportShuttle}
                        onChange={changeairportShuttle}
                        aria-label="Text for screen reader"
                      />{" "}
                      Airport Shuttle
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-check" style={{ paddingLeft: "35px" }}>
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={spa}
                        onChange={changeSpa}
                        aria-label="Text for screen reader"
                      />{" "}
                      Spa
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-check" style={{ paddingLeft: "35px" }}>
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={nonSmokingRooms}
                        onChange={changenonSmokingRooms}
                        aria-label="Text for screen reader"
                      />{" "}
                      Non Smoking Rooms
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-check" style={{ paddingLeft: "35px" }}>
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={restaurant}
                        onChange={changeRestaurant}
                        aria-label="Text for screen reader"
                      />{" "}
                      Restaurant
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-check" style={{ paddingLeft: "35px" }}>
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={freeWifi}
                        onChange={changefreeWifi}
                        aria-label="Text for screen reader"
                      />{" "}
                      Free Wi-Fi
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-check" style={{ paddingLeft: "35px" }}>
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={coffeeMaker}
                        onChange={changecoffeeMaker}
                        aria-label="Text for screen reader"
                      />{" "}
                      Tea/Coffee Maker In Room
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-check" style={{ paddingLeft: "35px" }}>
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={bar}
                        onChange={changeBar}
                        aria-label="Text for screen reader"
                      />{" "}
                      Bar
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-check" style={{ paddingLeft: "35px" }}>
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={lifts}
                        onChange={changeLifts}
                        aria-label="Text for screen reader"
                      />{" "}
                      Lifts
                    </label>
                  </div>
                </div>

                {/* <div className="col-md-3">
                  <div className="form-check">
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={coffeemaker}
                        onChange={changeCoffeemaker}
                        aria-label="Text for screen reader"
                      />{" "}
                      Tea/Coffee Maker In Room
                    </label>
                  </div>
                </div> */}

                <div className="col-md-3">
                  <div className="form-check" style={{ paddingLeft: "35px" }}>
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={food}
                        onChange={changeFood}
                        aria-label="Text for screen reader"
                      />{" "}
                      Breakfast/Lunch/Dinner
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-check" style={{ paddingLeft: "35px" }}>
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={parking}
                        onChange={changeParking}
                        aria-label="Text for screen reader"
                      />{" "}
                      Ample Parking
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-check" style={{ paddingLeft: "35px" }}>
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={rooms}
                        onChange={changeROoms}
                        aria-label="Text for screen reader"
                      />{" "}
                      Meeting/Confrence Rooms
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-check" style={{ paddingLeft: "35px" }}>
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={washingMachine}
                        onChange={changewashingMachine}
                        aria-label="Text for screen reader"
                      />{" "}
                      Washing Machine
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-check" style={{ paddingLeft: "35px" }}>
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={gym}
                        onChange={changeGym}
                        aria-label="Text for screen reader"
                      />{" "}
                      Gym
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-check" style={{ paddingLeft: "35px" }}>
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={garbage}
                        onChange={changeGarbage}
                        aria-label="Text for screen reader"
                      />{" "}
                      Garbage Collection
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-check" style={{ paddingLeft: "35px" }}>
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={laundry}
                        onChange={changeLaundry}
                        aria-label="Text for screen reader"
                      />{" "}
                      Laundry Service
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-check" style={{ paddingLeft: "35px" }}>
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={housekeeping}
                        onChange={changeHousekeeping}
                        aria-label="Text for screen reader"
                      />{" "}
                      Housekeeping
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-check" style={{ paddingLeft: "35px" }}>
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={cctv}
                        onChange={changeCctv}
                        aria-label="Text for screen reader"
                      />{" "}
                      CCTV
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-check" style={{ paddingLeft: "35px" }}>
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={hotWater}
                        onChange={changehotWater}
                        aria-label="Text for screen reader"
                      />{" "}
                      Hot Water
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-check" style={{ paddingLeft: "35px" }}>
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={bills}
                        onChange={changeBills}
                        aria-label="Text for screen reader"
                      />{" "}
                      Water and Electric Bills
                    </label>
                  </div>
                </div>
              

                <div className="col-md-3">
                  <div className="form-check" style={{ paddingLeft: "35px" }}>
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={cookers}
                        onChange={changeCookers}
                        aria-label="Text for screen reader"
                      />{" "}
                      Cookers
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-check" style={{ paddingLeft: "35px" }}>
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={security}
                        onChange={changeSecurity}
                        aria-label="Text for screen reader"
                      />{" "}
                      24 hour Security
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-check" style={{ paddingLeft: "35px" }}>
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={generator}
                        onChange={changeGenerator}
                        aria-label="Text for screen reader"
                      />{" "}
                      Standby Backup Generator
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-check" style={{ paddingLeft: "35px" }}>
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={dstv}
                        onChange={changeDstv}
                        aria-label="Text for screen reader"
                      />{" "}
                      TV/DSTV
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-check" style={{ paddingLeft: "35px" }}>
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={fence}
                        onChange={changeFence}
                        aria-label="Text for screen reader"
                      />{" "}
                      Electric Fence
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-check" style={{ paddingLeft: "35px" }}>
                    <label style={label} className="form-check-label">
                      <input
                        className="form-check-input"
                        name=""
                        id=""
                        type="checkbox"
                        checked={frontView}
                        onChange={changefrontView}
                        aria-label="Text for screen reader"
                      />{" "}
                      Beach front/ Garden Front/ City View
                    </label>
                  </div>
                </div>
                
              </Row>
        </>
    )
}

export default StayAmenities
