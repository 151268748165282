import React, { useState, useEffect } from "react";
import { Container, Table, Toast, Button, Col, Row, InputGroup, FormControl, Card, Form, SplitButton, Dropdown, DropdownButton } from "react-bootstrap";
import { StyledModal } from '../../../components/Modals/ModalStyles';
import { FaCaretDown, FaTimes, FaEllipsisH, FaImage, FaSearch } from "react-icons/fa";
import img from "../../../assets/img/Web/Spacia/Ellipse.png";
import SERVICES from "../../../services";
import { Constants } from '../../../utils/constants';
import { useHistory, Link } from "react-router-dom";
import check from "../../../assets/img/Web/Spacia/check.png";

import InformationModal from "../../../components/informationModal";
import FeedBackModal from "../../../components/feedbackModal";
import FormModal from "../../../components/FormModal";
import FeedbackModall from "../../../components/FeedbackModall";

import { InputTags } from "react-bootstrap-tagsinput";
import "react-bootstrap-tagsinput/dist/index.css";
import axios from "axios";
import PendingUserTableRow from "../../../components/PendingUserTableRow";
import VerifiedUserTableRow from "../../../components/VerifiedUserTableRow";
import ReactPaginate from "react-paginate";
import { UserManagementStyles } from "../UserManagementStyles";
import Pagination from "../../../components/Pagination";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
// import modal ract bootstreap
import { Modal } from 'react-bootstrap';
import { ModalStyles } from "../../../components/Modals/ModalStyles";
import { ErrorAlert } from "../../../components/Settings/alerts/ErrorAlert";
import { SuccessAlert } from "../../../components/Settings/alerts/SuccessAlert";
import { v4 as uuidv4 } from 'uuid';
import { ResetPasswordModal } from "../ResetPasswordModal";
import { Bundle } from "../../../data/package-select-data";
import apiService from "../../../services/apiService";

const UserManagement = () => {
	const [verifiedSearchValue, setVerifiedSearchValue] = useState('');
	const [unVerifiedSearchValue, setUnverifiedSearchValue] = useState('');
	const [verifiedCurrentPage, setVerifiedCurrentPage] = useState(1);
	const [unverifiedCurrentPage, setUnverifiedCurrentPage] = useState(1);
	const [verifiedTotalPageSize, setVerifiedTotalPageSize] = useState(0);
	const [verifiedItemsPerPage, setVerifiedItemsPerPage] = useState(10);
	const [unverifiedTotalPageSize, setUnverifiedTotalPageSize] = useState(0);
	const [unverifiedItemsPerPage, setUnverifiedItemsPerPage] = useState(10);

	const [isRoleNotSelected, setIsRoleNotSelected] = useState(false);
	const [isBundleNotSelected, setIsBundleNotSelected] = useState(false);
	const [isBulkBundleNotSelected, setIsBulkBundleNotSelected] = useState(false);
	const [noAddedEmail, setNoAddedEmail] = useState(false);
	const [isBulkRoleNotSelected, setIsBulkRoleNotSelected] = useState(false);
	const [isInviteFileNotChosen, setIsInviteFileNotChosen] = useState(false);
	const [errorAlert, setErrorAlert] = useState(false);
	const [showInviteError, setShowInviteError] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');
	const [inviteErrorMsg, setInviteErrorMsg] = useState('');
	const [bundles, setBundles] = useState([]);
	const [bundleMetrics, setBundleMetrics] = useState([]);
	const [currentUserId, setCurrentUserId] = useState(0);

	// get all bundles on system
	useEffect(() => {
		const currentUser = SERVICES.getUser();
		const ownerId = currentUser ? currentUser.id : 0;

		apiService.getBundleMetrics(ownerId)
			.then(res => {
				const response = res.data;
				const responseData = response?.data;

				if (responseData != null) {
					const availablePackages = responseData
						.filter(e => e.totalAvailable > 0)
						.map(e => e.bundle);

					setBundles(availablePackages);
				}
			})
			.catch(err => {
				// handle error
			});
	}, []);

	// Fetch bundle metrics when component mounts or currentUserId changes
	useEffect(() => {
		if (currentUserId) {
			apiService.getBundleMetrics(currentUserId)
				.then(res => {
					const responseData = res.data?.data;
					if (responseData) {
						setBundleMetrics(responseData);
					}
				})
				.catch(err => {
					console.error('Error fetching bundle metrics:', err);
				});
		}
	}, [currentUserId]);

	// Functions
	const deleteUser = (id) => {
		setDeleteShow(true);
		setUserId(id);
	};
	const disableUser = (id) => {
		setDisableModal(true);
		setUserId(id);
	};

	const enableUser = (id) => {
		setEnableModal(true);
		setUserId(id);
	};

	const resetUserPassword = (email) => {
		setResetPasswordModal(true);

		console.log('about to set email of selected user. email: [ ' + email + ' ]');
		setEmailOfSelectedUser(email);
	};

	const [currentSelectedUser, setCurrentSelectedUser] = useState({});
	const [currentUserRole, setCurrentUserRole] = useState('');
	const [subAccountRoles, setSubAccountRoles] = useState([]);
	const [bulkSubAccountRoles, setBulkSubAccountRoles] = useState([]);
	// const [currentSelectedName, setCurrentSelectedName] = useState("Nana Kweku");
	// const [currentSelectedEmail, setCurrentSelectedEmail] = useState("mr.adumatta@gmail.com");
	// const [currentSelectedMobile, setCurrentSelectedMobile] = useState("0545977791");

	const defaultAvatar = 'https://storage.googleapis.com/maestro-8beff.appspot.com/user-543-_1626834155761_69ec79f5_1b40_4716_9958_1a6f557f6581.jpeg';


	// const disableuser = (id) => {
	// 	axios
	// 		.patch(`${Constants.BASE_URL}/users/api/v1/users/${id}/status`, {
	// 			params: { active: false },
	// 		})
	// 		.then((res) => console.log(res));
	// };
	// STYLES
	const admin = {
		backgroundColor: "#F3D5D1",
		color: "#F85A47",
		border: "none",
		fontWeight: "bold",
		borderRadius: 5,
		paddingTop: 4,
		paddingBottom: 4,
		paddingLeft: 20,
		paddingRight: 20,
	};
	const approver = {
		backgroundColor: "#FEE6C2",
		color: "#F99500",
		border: "none",
		fontWeight: "bold",
		borderRadius: 5,
		paddingTop: 4,
		paddingBottom: 4,
		paddingLeft: 20,
		paddingRight: 20,
	};
	const employee = {
		backgroundColor: "#D7EFD7",
		color: "#02BD04",
		border: "none",
		fontWeight: "bold",
		borderRadius: 5,
		paddingTop: 4,
		paddingBottom: 4,
		paddingLeft: 20,
		paddingRight: 20,
	};

	const inactive = {
		color: "#9B9B9B",
		fontWeight: "bold",
	};

	const active = {
		color: "#2B86FF",
		fontWeight: "bold",
	};

	const [userId, setUserId] = useState(0);
	const [emailOfSelectedUser, setEmailOfSelectedUser] = useState('');

	const [unVerifiedUsers, setUnVerifiedUsers] = useState([]);
	const [verifiedUsers, setVerifiedUsers] = useState([]);
	const [pendingDeletions, setPendingDeletions] = useState([]);
	const [pendingDeletionsCurrentPage, setPendingDeletionsCurrentPage] = useState(1);
	const [pendingDeletionsTotalPageSize, setPendingDeletionsTotalPageSize] = useState(0);
	const [pendingDeletionsSearchValue, setPendingDeletionsSearchValue] = useState('');

	//set state to toggle invite user
	const [show, setShow] = useState(false);

	//set state to toggle edit user
	const [editShow, setEditShow] = useState(false);

	//set state to toggle delete user
	const [deleteshow, setDeleteShow] = useState(false);

	//set state to toggle confirm delete user
	const [confirmDelete, setConfirmDelete] = useState(false);

	//set state to toggle confirm adding user
	const [confirmUserAddition, setConfirmUserAddition] = useState(false);

	const [openProfileUpdate, setOpenProfileUpdate] = useState(false);
	const [profileUpdated, setProfileUpdated] = useState(false);
	// const [loaded, setLoaded] = useState(false);

	const [role, setRole] = useState("");
	const [bulkRole, setBulkRole] = useState("");
	const [bulkPackagePlan, setBulkPackagePlan] = useState("");
	const [selectedFile, setSelectedFile] = useState('');
	const [selectedBundle, setSelectedBundle] = useState('');

	const [state, setState] = useState([]);

	useEffect(() => {
		console.log('state holding email of invitees:', state);
	}, [state]);

	const [UnableToConfirmUserAddition, setUnableToConfirmUserAddition] =
		useState(false);

	useEffect(() => {
		const currentUser = SERVICES.getUser();
		const currentUserRole = currentUser?.role;
		const id = currentUser ? currentUser.id : 0;

		console.log('current user role:', currentUserRole);

		setCurrentUserRole(currentUserRole);
		setCurrentUserId(id);
	}, []);

	useEffect(() => {
		switch (currentUserRole) {
			case 'ROLE_SUBSCRIBER_OWNER':
				// set value and label
				const ownerSubAccounts = [
					// {
					// 	value: 'ROLE_SUBSCRIBER_APPROVER',
					// 	label: 'Approver'
					// },
					// {
					// 	value: 'ROLE_SUBSCRIBER_INDIVIDUAL',
					// 	label: 'Regular'
					// },
					{
						value: 'ROLE_APPROVER_EMPLOYEE',
						label: 'Administrator (can book meetings)'
					},
					{
						value: 'ROLE_EMPLOYEE',
						label: 'Employee'
					}
				];

				setSubAccountRoles(ownerSubAccounts);
				break;

			case 'ROLE_HOST':
				// set value and label
				const hostSubAccounts = [
					{
						value: 'ROLE_HOST_OPERATIONS',
						label: 'Operations Account'
					}
				];
				setSubAccountRoles(hostSubAccounts);
				break;

			case 'ROLE_ADMINISTRATOR':
				// set value and label
				const adminSubAccounts = [
					{
						value: 'ROLE_ADMINISTRATOR',
						label: 'Platform Admin'
					}
				];
				setSubAccountRoles(adminSubAccounts);
		}
	}, [currentUserRole]);

	useEffect(() => {
		switch (currentUserRole) {
			case 'ROLE_SUBSCRIBER_OWNER':
				// set value and label
				const ownerSubAccounts = [
					{
						value: 'ROLE_APPROVER_EMPLOYEE',
						label: 'Administrator (can book meetings)'
					},
					{
						value: 'ROLE_EMPLOYEE',
						label: 'Employee'
					}
				];

				setBulkSubAccountRoles(ownerSubAccounts);
				break;

			case 'ROLE_HOST':
				// set value and label
				const hostSubAccounts = [
					{
						value: 'ROLE_HOST_OPERATIONS',
						label: 'Operations Account'
					}
				];
				setBulkSubAccountRoles(hostSubAccounts);
				break;

			case 'ROLE_ADMINISTRATOR':
				// set value and label
				const adminSubAccounts = [
					{
						value: 'ROLE_ADMINISTRATOR',
						label: 'Platform Admin'
					}
				];
				setBulkSubAccountRoles(adminSubAccounts);
		}
	}, [currentUserRole]);

	// Functions to toggle Invite User Modal
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	// Functions to toggle Delete Modal
	const handleDeleteClose = () => {
		setDeleteShow(false);
		setUserToDelete(null);
	};
	const handleDeleteShow = (id) => {
		// console.log(id)
		console.log(id);
		setDeleteShow(true);
		deleteUser(id);
	};

	// Functions to toggle Edit User Modal
	const handleEditClose = () => setEditShow(false);
	const handleEditShow = (user) => {
		console.log('incoming user:', user);
		// set current user
		setCurrentSelectedUser(user);
		setEditShow(true);
	};

	// Functions to toggle confirm user deletion Modal
	const confirmDeleteClose = () => setConfirmDelete(false);
	const confirmDeleteOpen = () => {
		confirmDeleteUser();
		setDeleteShow(false);
	};

	// Functions to toggle confirm adding user
	const confirmUserClose = () => setConfirmUserAddition(false);
	const confirmUserOpen = () => {
		setConfirmUserAddition(true);
		setEditShow(!editShow);
	};

	const handleCloseProfileUpdate = () => setOpenProfileUpdate(false);

	const updateUserProfile = (updatedUser) => {
		setProfileUpdated(true);
		setEditShow(false);

		setVerifiedUsers([updatedUser, ...(verifiedUsers.filter(user => user.id !== updatedUser.id))]);
	};

	// Functions to toggle disable user
	const disableUserClose = () => setDisableModal(false);
	const confirmDisableUser = () => {
		setDisableModal(false);

		const currentUser = SERVICES.getUser();
		const currentUserId = currentUser ? currentUser.id : 0;

		apiService.updateUserStatusActive(userId, false, currentUserId)
			.then((res) => {
				const user = verifiedUsers.find((u) => u.id === userId);
				user.enabled = false;
				setVerifiedUsers(verifiedUsers);
				setConfirmDisableModal(true);
			});
	};



	const [enableModal, setEnableModal] = useState(false);
	const [confirmEnableModal, setConfirmEnableModal] = useState(false);
	const enableUserClose = () => setConfirmUserAddition(false);
	const confirmEnableUser = () => {
		setEnableModal(false);

		const currentUser = SERVICES.getUser();
		const currentUserId = currentUser ? currentUser.id : 0;

		apiService.updateUserStatusActive(userId, true, currentUserId)
			.then((res) => {
				const user = verifiedUsers.find((u) => u.id === userId);
				user.enabled = true;
				setVerifiedUsers(verifiedUsers);
				setConfirmEnableModal(true);
			}).catch(err => {
				console.log('error here:', err.response.data);
				const errorResponse = err.response.data;
				const errors = errorResponse?.errors;
				const errorAt0 = (errors && errors.length > 0) ? errors[0] : null;

				const message = errorAt0?.message;

				setErrorAlert(true);
				setErrorMsg(message || 'An error occurred! Please try again');
			});
	};


	const [disableModal, setDisableModal] = useState(false);
	const [confirmDisableModal, setConfirmDisableModal] = useState(false);

	const handleDisableUser = () => {
		console.log("Here");
		setDisableModal(true);
	};

	const loaded = true;

	// Reset Password

	const [resetPasswordModal, setResetPasswordModal] = useState(false);
	const [confirmResetPasswordModal, setConfirmResetPasswordModal] =
		useState(false);

	const closeResetPasswordModal = () => {
		setResetPasswordModal(false);
	};

	const confirmResetPassword = () => {
		const emailOfUser = emailOfSelectedUser || '';
		apiService.resetUserPassword(emailOfUser)
			.then((res) => {
				// email sent successfully
				setResetPasswordModal(false);
				setConfirmResetPasswordModal(true);
			})
			.catch((err) => {
				console.log(err.response.data);
				const errorObj = err.response.data;

				const errors = errorObj ? errorObj.errors : [];
				const index0 = (errors.length > 0) ? errors[0] : null;

				const errorMsg = index0 ? index0.message : 'An error occurred. Please try again.';
				setErrorMsg(errorMsg);

				setErrorAlert(true);
			});
	};

	useEffect(() => {
		if (role) {
			setIsRoleNotSelected(false);
		}
		if (bulkRole) {
			setIsBulkRoleNotSelected(false);
		}
		if (bulkPackagePlan) {
			setIsBulkBundleNotSelected(false);
		}
		if (selectedFile) {
			setIsInviteFileNotChosen(false);
		}
	}, [role, bulkRole, selectedFile]);

	useEffect(() => {
		if (state.length > 0) {
			setNoAddedEmail(false);
		}
	}, [state]);

	const areFieldsOkay = () => {
		const requiredFields = [role];
		// is any of the fields empty
		const anyEmpty = requiredFields.some(field => !field) || state.length === 0;
		if (anyEmpty) {
			if (!role) {
				setIsRoleNotSelected(true);
			}
			if (state.length === 0) {
				setNoAddedEmail(true);
			}
			return false;
		} else {
			return true;
		}

	};

	const areFieldsOkayForBulk = () => {
		const requiredFields = [selectedFile, bulkRole];
		// is any of the fields empty
		const anyEmpty = requiredFields.some(field => !field);
		if (anyEmpty) {
			if (!bulkRole) {
				setIsBulkRoleNotSelected(true);
			}

			if (!bulkPackagePlan) {
				setIsBulkBundleNotSelected(true);
			}

			if (!selectedFile) {
				setIsInviteFileNotChosen(true);
			}
			return false;
		} else {
			return true;
		}
	};

	let history = useHistory();

	const checkBundleAvailability = () => {
		// Find the bundle with non-null metrics that matches the selected bundle type
		const activeBundle = bundleMetrics.find(metric => {
			const isActiveMetrics = metric.totalPurchased !== null || 
				metric.totalUsedUp !== null || 
				metric.totalAvailable !== null;
			const matchesSelectedBundle = metric.bundle?.bundleType?.value === selectedBundle;
			return isActiveMetrics && matchesSelectedBundle;
		});

		if (!activeBundle) {
			setErrorAlert(true);
			setErrorMsg('No active bundle found. Please purchase a bundle to invite users.');
			return false;
		}

		if (activeBundle.totalAvailable <= 0) {
			setErrorAlert(true);
			setErrorMsg(`You have reached the maximum number of users for your ${activeBundle.bundle?.bundleType?.label || 'current'} bundle. Please upgrade your bundle to invite more users.`);
			return false;
		}

		// Check if trying to invite more users than available
		const inviteCount = Array.isArray(state) ? state.length : 1;
		if (activeBundle.totalAvailable < inviteCount) {
			setErrorAlert(true);
			setErrorMsg(`You can only invite ${activeBundle.totalAvailable} more user${activeBundle.totalAvailable === 1 ? '' : 's'} with your current bundle. Please upgrade your bundle or reduce the number of invitations.`);
			return false;
		}

		return true;
	};

	const inviteUser = () => {
		if (!areFieldsOkay()) return;
		if (!checkBundleAvailability()) return;

		const payload = {
			emailsOfInvitees: state,
			inviterId: currentUserId,
			role: role,
			bundleType: selectedBundle
		};

		apiService.inviteUsers(payload)
			.then(res => {
				const response = res.data;
				const responseError = response?.errors;

				const hasError = (responseError != null) && (responseError.length > 0);
				if (hasError) {
					const errorMessage = responseError[0].message;
					setErrorAlert(true);
					setErrorMsg(errorMessage);
				}

				setShow(false);
				setConfirmUserAddition(true);

				// Refresh sub-accounts list
				apiService.getSubAccounts(currentUserId, { sortBy: "updatedDate" })
					.then(res => {
						if (res.status === 200) {
							const subAccounts = res.data.data;
							setVerifiedUsers(subAccounts.filter((s) => s.verified));
							setUnVerifiedUsers(subAccounts.filter((s) => !s.verified));
						}
					});
			})
			.catch(err => {
				console.log(err);
				const errorResponse = err.response;
				const data = errorResponse?.data;
				const errors = data?.errors;

				setErrorAlert(true);

				if (errors && Array.isArray(errors) && errors.length > 0) {
					const { message } = errors[0];
					setErrorMsg(message);
				}

				setShow(false);
				setUnableToConfirmUserAddition(true);
			});
	};

	const handleFileChange = (event) => {
		setSelectedFile(event.target.files[0]);
	};

	const bulkUpload = () => {
		if (!areFieldsOkayForBulk()) return;
		if (!checkBundleAvailability()) return;

		const user = SERVICES.getUser();

		const inviterId = user ? user.id : 0;

		// let userRole = undefined;
		// switch(bulkRole) {
		//     case 'ROLE_SUBSCRIBER_INDIVIDUAL':
		//         userRole = 'REGULAR_SUBSCRIBER';
		//         break;

		//     case 'ROLE_SUBSCRIBER_APPROVER':
		//         userRole = 'APPROVER_SUBSCRIBER';
		//         break;

		// 	case "ROLE_EMPLOYEE":
		// 		userRole = 'EMPLOYEE';
		//         break;

		// 	case "ROLE_APPROVER_EMPLOYEE":
		// 		userRole = 'APPROVER_EMPLOYEE';
		// 		break;

		//     default:
		//         userRole = '';
		// }

		let formData = new FormData();

		formData.append('inviterId', inviterId);
		formData.append('role', bulkRole);
		formData.append('bundleType', bulkPackagePlan);
		formData.append('file', selectedFile);

		apiService.bulkInviteUsers(formData)
			.then(res => {
				setShow(false);
				setConfirmUserAddition(true);

				apiService.getSubAccounts(inviterId, { sortBy: "createdDate" })
					.then((res) => {
						if (res.status === 200) {
							const subAccounts = res.data.data;
							console.log('sub accounts:', subAccounts);
							setVerifiedUsers(subAccounts.filter((s) => s.verified));
							setUnVerifiedUsers(subAccounts.filter((s) => !s.verified));
						}
					});
			})
			.catch(err => {
				console.log(err);
				const errorResponse = err.response;
				const data = errorResponse?.data;
				const errors = data?.errors;

				console.log(errors);
				setErrorAlert(true);

				if (errors && Array.isArray(errors) && errors.length > 0) {
					const { message } = errors[0];
					setErrorMsg(message);
				}

				setShow(false);
				setUnableToConfirmUserAddition(true);
			});
	};

	const handleRoleChange = (e) => {
		setRole(e.target.value);
	};

	const handleBulkRoleChange = (e) => {
		setBulkRole(e.target.value);
	};

	const handleBundleChange = (e) => {
		setSelectedBundle(e.target.value);
	};

	const handleBulkBundleChange = (e) => {
		setBulkPackagePlan(e.target.value);
	};
	// const deleteTask = async (id) =>{
	//     await fetch(`http://localhost:5000/tasks/${id}`,{
	//       method:'DELETE'
	//     })
	//     setTasks(tasks.filter((task) => task.id !== id))
	//     console.log('deleted', id)
	//   }

	const deleteuser = (id) => {
		const user = SERVICES.getUser();
		const ownerId = user ? user.id : 0;

		console.log('user id is', id, 'and owner id is', ownerId);

		apiService.deleteUser(id, ownerId)
			.then((res) => {
				console.log(res);
			})
			.catch((err) => console.log(err));
	};

	// get all sub-accounts under owner
	useEffect(() => {
		const currentUser = SERVICES.getUser();
		const ownerId = currentUser ? currentUser.id : 0;
		// const resourceUrl = `${Constants.BASE_URL}/users/api/v1/users/${ownerId}/sub-accounts`;

		console.log('getting all approved users');

		// get verified users
		apiService.getSubAccounts(ownerId, {
			sortBy: "updatedDate",
			verified: true,
			page: 0
		})
			.then((res) => {
				if (res.status === 200) {
					console.log(res);
					const subAccounts = res.data.data;

					// const verifiedUsers = subAccounts.filter((s) => s.verified);
					setVerifiedUsers(subAccounts);

					setVerifiedTotalPageSize(res.data.totalCount);
					// setTotalPageSize(500);
					setVerifiedItemsPerPage(res.data.pageSize);

					// const unverifiedUsers = subAccounts.filter((s) => !s.verified);
					// setUnVerifiedUsers(unverifiedUsers);
				}
			});
	}, []);

	useEffect(() => {
		const currentUser = SERVICES.getUser();
		const ownerId = currentUser ? currentUser.id : 0;
		// const resourceUrl = `${Constants.BASE_URL}/users/api/v1/users/${ownerId}/sub-accounts`;

		// get unverified users
		apiService.getSubAccounts(ownerId, {
			sortBy: "createdDate",
			verified: false,
			page: 0
		})
			.then((res) => {
				if (res.status === 200) {
					console.log(res);
					const subAccounts = res.data.data;

					// const verifiedUsers = subAccounts.filter((s) => s.verified);
					setUnVerifiedUsers(subAccounts);

					setUnverifiedTotalPageSize(res.data.totalCount);
					// setTotalPageSize(500);
					setUnverifiedItemsPerPage(res.data.pageSize);

					// const unverifiedUsers = subAccounts.filter((s) => !s.verified);
					// setUnVerifiedUsers(unverifiedUsers);
				}
			});
	}, []);

	useEffect(() => {
		const currentUser = SERVICES.getUser();
		const ownerId = currentUser ? currentUser.id : 0;

		// Only fetch pending deletions for administrators
		if (currentUserRole === 'ROLE_ADMINISTRATOR') {
			apiService.getSubAccounts(ownerId, { 
				sortBy: "createdDate", 
				verified: true,
				pendingDelete: true,
				page: pendingDeletionsCurrentPage - 1,
				search: pendingDeletionsSearchValue
			})
			.then(response => {
				if (response.status === 200) {
					setPendingDeletions(response.data.data);
					setPendingDeletionsTotalPageSize(response.data.totalCount);
				}
			});
		}
	}, [currentUserRole, pendingDeletionsCurrentPage, pendingDeletionsSearchValue]);

	useEffect(() => {
		const currentUser = SERVICES.getUser();
		const ownerId = currentUser ? currentUser.id : 0;

		if (!ownerId) return;

		apiService.getSubAccounts(ownerId, { 
			sortBy: "createdDate", 
			verified: true,
			pendingDelete: true,
			page: 0
		})
		.then(response => {
			if (response.status === 200) {
				setPendingDeletions(response.data.data);
				setPendingDeletionsTotalPageSize(response.data.totalCount);
			}
		});
	}, []);

	const changePendingDeletionsPage = (selected) => {
		const currentUser = SERVICES.getUser();
		const ownerId = currentUser ? currentUser.id : 0;

		const page = selected.selected;
		setPendingDeletionsCurrentPage(page); // Don't add 1

		apiService.getSubAccounts(ownerId, { 
			sortBy: "createdDate", 
			verified: true,
			pendingDelete: true,
			page: page
		})
		.then(response => {
			if (response.status === 200) {
				setPendingDeletions(response.data.data);
			}
		});
	};

	const performSearchForPendingDeletions = (e) => {
		e.preventDefault();
		const currentUser = SERVICES.getUser();
		const ownerId = currentUser ? currentUser.id : 0;

		apiService.getSubAccounts(ownerId, { 
			sortBy: "createdDate", 
			verified: true,
			pendingDelete: true,
			page: 0,
			search: pendingDeletionsSearchValue
		})
		.then(response => {
			if (response.status === 200) {
				setPendingDeletions(response.data.data);
				setPendingDeletionsTotalPageSize(response.data.totalCount);
			}
		});
	};

	const convertRole = (role) => {
		switch (role) {
			case "ROLE_SUBSCRIBER_APPROVER":
				return "APPROVER";
			case "ROLE_SUBSCRIBER_INDIVIDUAL":
				return "REGULAR";
			case "ROLE_CONTENT_PUBLISHER":
				return "CONTENT PUBLISHER";
			case "ROLE_HOST_OPERATIONS":
				return "OPERATIONS ACCOUNT";
			case "ROLE_SUBSCRIBER_OWNER":
				return "CORPORATE ADMIN";
			case "ROLE_HOST":
				return "HOST";
			case "ROLE_EMPLOYEE":
				return "EMPLOYEE";
			case "ROLE_APPROVER_EMPLOYEE":
				return "MEETING ADMIN";
			default:
				return "N/A";
		}
	};

	const convertFromVerifiedStatus = (user) => {
		if (user.verified) {
			if (user.enabled) {
				return "Active";
			}
			return "Inactive";
		} else {
			return "Pending";
		}
	};

	const performSearchForVerified = (e) => {
		e.preventDefault();

		console.log('current page (verified):', verifiedCurrentPage);

		const currentUser = SERVICES.getUser();
		const ownerId = currentUser ? currentUser.id : 0;
		// const resourceUrl = `${Constants.BASE_URL}/users/api/v1/users/${ownerId}/sub-accounts`;

		apiService.getSubAccounts(ownerId, {
			sortBy: "createdDate",
			verified: true,
			page: verifiedCurrentPage,
			search: verifiedSearchValue
		})
			.then((res) => {
				if (res.status === 200) {
					console.log(res);
					const subAccounts = res.data.data;

					setVerifiedUsers(subAccounts);

					setVerifiedTotalPageSize(res.data.totalCount);
					// setTotalPageSize(500);
					setVerifiedItemsPerPage(res.data.pageSize);
				}
			});
	};

	const performSearchForUnverified = (e) => {
		e.preventDefault();

		const currentUser = SERVICES.getUser();
		const ownerId = currentUser ? currentUser.id : 0;
		// const resourceUrl = `${Constants.BASE_URL}/users/api/v1/users/${ownerId}/sub-accounts`;

		apiService.getSubAccounts(ownerId, {
			sortBy: "createdDate",
			verified: false,
			page: unverifiedCurrentPage,
			search: unVerifiedSearchValue
		})
			.then((res) => {
				if (res.status === 200) {
					console.log(res);
					const subAccounts = res.data.data;

					setUnVerifiedUsers(subAccounts);

					setUnverifiedTotalPageSize(res.data.totalCount);
					// setTotalPageSize(500);
					setUnverifiedItemsPerPage(res.data.pageSize);
					// setUnVerifiedUsers(subAccounts.filter((s) => s.verified));
				}
			});
	};

	const changeVerifiedPage = (selected) => {
		const currentUser = SERVICES.getUser();
		const ownerId = currentUser ? currentUser.id : 0;

		// selected.selected is already the 0-based index we want to show
		setVerifiedCurrentPage(selected.selected); // For display only

		apiService.getSubAccounts(ownerId, {
			sortBy: "updatedDate",
			verified: true,
			page: selected.selected // Don't add 1 here since API expects 0-based
		})
			.then((res) => {
				if (res.status === 200) {
					setVerifiedUsers(res.data.data);
				}
			});
	};

	const changeUnverifiedPage = (selected) => {
		const currentUser = SERVICES.getUser();
		const ownerId = currentUser ? currentUser.id : 0;

		setUnverifiedCurrentPage(selected.selected); // For display only

		apiService.getSubAccounts(ownerId, {
			sortBy: "createdDate",
			verified: false,
			page: selected.selected // Don't add 1 here since API expects 0-based
		})
			.then((res) => {
				if (res.status === 200) {
					setUnVerifiedUsers(res.data.data);
				}
			});
	};

	// const changePendingDeletionsPage = (selected) => {
	// 	const currentUser = SERVICES.getUser();
	// 	const ownerId = currentUser ? currentUser.id : 0;

	// 	const page = selected.selected;
	// 	setPendingDeletionsCurrentPage(page); // For display only

	// 	apiService.get(`/users/api/v1/users/${ownerId}/sub-accounts`, {
	// 		params: {
	// 			sortBy: 'createdDate',
	// 			verified: false,
	// 			page: page // Don't add 1 here since API expects 0-based
	// 		}
	// 	})
	// 	.then(response => {
	// 		if (response.status === 200) {
	// 			setPendingDeletions(response.data.data);
	// 		}
	// 	});
	// };

	const downloadBulkUploadSample = () => {
		const link = document.createElement('a');
		link.href = 'https://storage.googleapis.com/download/storage/v1/b/spacia-16ab5.appspot.com/o/spacia-com-reg-doc-1647851464800-_nvitees%20List%20-%20Shee?generation=1647851458873027&alt=media';
		link.setAttribute('download', 'bulk upload sample.csv');
		document.body.appendChild(link);
		link.click();
	};

	const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
	const [userToDelete, setUserToDelete] = useState(null);

	const confirmDeleteUser = () => {
		if (!userToDelete) return;

		const currentUser = SERVICES.getUser();
		const ownerId = currentUser ? currentUser.id : 0;

		if (!ownerId) return;

		apiService.deleteUser(userToDelete.id, ownerId)
			.then((response) => {
				if (response.status === 200) {
					setPendingDeletions(prevUsers =>
						prevUsers.filter(user => user.id !== userToDelete.id)
					);
					setDeleteShow(false);
					setUserToDelete(null);
				}
			})
			.catch((error) => {
				console.error('Error deleting user:', error);
			});
	};

	const [showPendingDeleteModal, setShowPendingDeleteModal] = useState(false);
	const [pendingUserToDelete, setPendingUserToDelete] = useState(null);

	const handlePendingDeleteClose = () => {
		setShowPendingDeleteModal(false);
		setPendingUserToDelete(null);
	};

	const confirmPendingDeleteUser = () => {
		if (!pendingUserToDelete) return;

		apiService.delete(`/users/api/v1/user/${pendingUserToDelete.id}`)
			.then(response => {
				if (response.status === 200) {
					setPendingDeletions(prevUsers =>
						prevUsers.filter(user => user.id !== pendingUserToDelete.id)
					);
					setShowPendingDeleteModal(false);
					setPendingUserToDelete(null);
				}
			})
			.catch(error => {
				console.error('Error deleting pending user:', error);
			});
	};

	const PendingDeletionRow = ({ user }) => {
		return (
			<tr key={user.id}>
				<td>
					<div className="d-flex align-items-center">
						<img
							src={user.avatar || defaultAvatar}
							alt=""
							style={{
								width: "45px",
								height: "45px",
								borderRadius: "50%",
								marginRight: "10px",
							}}
						/>
						<div>
							<p className="fw-bold mb-1">{`${user.firstName} ${user.lastName}`}</p>
						</div>
					</div>
				</td>
				<td>
					<p className="fw-normal mb-1">{user.username}</p>
				</td>
				<td>
					<span style={approver}>{convertRole(user.role)}</span>
				</td>
				<td>
					<span style={convertFromVerifiedStatus(user) === 'Active' ? active : inactive}>
						{convertFromVerifiedStatus(user)}
					</span>
				</td>
				<td>
					<Dropdown>
						<Dropdown.Toggle
							variant="link"
							id={`dropdown-${user.id}`}
							style={{ color: 'black', boxShadow: 'none' }}
						>
							<FaEllipsisH />
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item
								onClick={() => {
									setPendingUserToDelete(user);
									setShowPendingDeleteModal(true);
								}}
							>
								Delete
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</td>
			</tr>
		);
	};

	return (

		<UserManagementStyles>
			<Container>
				<SuccessAlert show={openProfileUpdate} message={"Successful"} close={() => setOpenProfileUpdate(false)} />
				<SuccessAlert show={confirmDisableModal} message={"Successful"} close={() => setConfirmDisableModal(false)} />
				<SuccessAlert show={confirmResetPasswordModal} message={"Successfully sent reset password"} close={() => setConfirmResetPasswordModal(false)} />
				<SuccessAlert show={confirmEnableModal} message={"Successfully enabled user"} close={() => setConfirmEnableModal(false)} />
				<SuccessAlert show={confirmDelete} message={"Successful"} close={() => setConfirmDelete(false)} />
				<SuccessAlert show={confirmUserAddition} message={"User invitation sent"} close={() => setConfirmUserAddition(false)} />
				<ErrorAlert show={errorAlert} message={errorMsg || "Please try again"} close={() => setErrorAlert(false)} />
				<Row className="mb-4">
                    <div className="col-12">
                        <div className="d-flex justify-content-between align-items-center">
                            <h4>User Management</h4>
                            <div className="flex-grow-1 d-flex justify-content-center mx-4">
                                <form className="form-inline my-2 my-xl-0" onSubmit={performSearchForVerified}>
                                    <input 
                                        style={{ 
                                            borderTopRightRadius: 0, 
                                            borderBottomRightRadius: 0, 
                                            marginRight: '0!important' 
                                        }} 
                                        className="form-control mr-sm-2 search" 
                                        placeholder='Search verified users' 
                                        type="search" 
                                        aria-label="Search" 
                                        value={verifiedSearchValue} 
                                        onChange={e => setVerifiedSearchValue(e.target.value)} 
                                    />
                                    <button 
                                        style={{ 
                                            borderTopLeftRadius: 0, 
                                            borderBottomLeftRadius: 0 
                                        }} 
                                        className="btn btn-outline-dark my-2 my-sm-0" 
                                        type="submit"
                                    >
                                        <FaSearch />
                                    </button>
                                </form>
                            </div>
                            <Button 
                                variant="primary"
                                onClick={handleShow}
                            >
                                Invite Users
                            </Button>
                        </div>
                    </div>
                </Row>
				<Row className="mt-4">
					<Col>
						<Card className="min-h-50">
							<Card.Body>
								<Row className="align-items-center">
									<Col md="5">
										<h6 style={{ fontWeight: "bold", marginBottom: 20 }}>Accounts</h6>
										<form className="form-inline my-2 my-xl-0" onSubmit={performSearchForVerified}>
											<input 
                                                style={{ 
                                                    borderTopRightRadius: 0, 
                                                    borderBottomRightRadius: 0, 
                                                    marginRight: '0!important' 
                                                }} 
                                                className="form-control mr-sm-2 search" 
                                                placeholder='Search verified users' 
                                                type="search" 
                                                aria-label="Search" 
                                                value={verifiedSearchValue} 
                                                onChange={e => setVerifiedSearchValue(e.target.value)} 
                                            />
											<button 
                                                style={{ 
                                                    borderTopLeftRadius: 0, 
                                                    borderBottomLeftRadius: 0 
                                                }} 
                                                className="btn btn-outline-dark my-2 my-sm-0" 
                                                type="submit"
                                            >
												<FaSearch />
											</button>
										</form>
									</Col>
									<Col md="auto" className="text-right offset-4">
										{/*<Row>
											<Col md="auto"><span className="text-muted">Sort by</span></Col>
											<Col>
												<Dropdown>
													<Dropdown.Toggle as={Link} className="text-dark font-weight-bold">
														Status-invited
													</Dropdown.Toggle>

													<Dropdown.Menu>
														<Dropdown.Item eventKey="1">1</Dropdown.Item>
														<Dropdown.Item eventKey="2">2</Dropdown.Item>
													</Dropdown.Menu>
												</Dropdown>
											</Col>
										</Row>*/}
									</Col>
								</Row>
								<Table className="mt-3" striped>
									<thead>
										<tr className="tableUnderline">
											<th>Name</th>
											<th>Email</th>
											<th>Status</th>
											<th>Role</th>
											{
												(currentUserRole && currentUserRole === 'ROLE_SUBSCRIBER_OWNER')
												&& <th>Employee Plan</th>
											}
											<th>

											</th>
										</tr>
									</thead>
									<tbody>
										{verifiedUsers.length > 0 &&
											// verifiedUsers.filter(user => user.id !== currentUserId).map((user, i) =>  {
											verifiedUsers.map((user, i) => {
												return (
													<VerifiedUserTableRow
														key={uuidv4()}
														profile={user.avatar || defaultAvatar}
														name={`${user.firstName} ${user.lastName}`}
														email={user.username}
														packagePlan={user.packagePlan}
														currentUserRole={currentUserRole}
														onDelete={() =>
															deleteuser(user.id)
														}
														onEdit={() => handleEditShow(user)}
														statusStyle={inactive}
														onDisable={() => {
															const status = convertFromVerifiedStatus(user);
															if (status.toLowerCase().indexOf('inactive') > -1) {
																enableUser(user.id);
															} else {
																disableUser(user.id);
															}

														}
														}
														onReset={() => {
															resetUserPassword(user.username);
														}}
														status={convertFromVerifiedStatus(
															user
														)}
														role={convertRole(
															user.role
														)}
														style={approver}
														isCurrentUser={currentUserId === user.id}
													/>
												);
											})}
									</tbody>
									{
										Array.isArray(verifiedUsers) && verifiedUsers.length > 0
										&& <caption>
											<Pagination
												className="pagination-bar"
												currentPage={verifiedCurrentPage}
												totalCount={verifiedTotalPageSize}
												pageSize={verifiedItemsPerPage}
												onPageChange={changeVerifiedPage}
											/>
											{/*<ReactPaginate*/}
											{/*	previousLabel={'<'}*/}
											{/*	nextLabel={'>'}*/}
											{/*	pageCount={verifiedPageCount}*/}
											{/*	marginPagesDisplayed={0}*/}
											{/*	pageRangeDisplayed={6}*/}
											{/*	onPageChange={changeVerifiedPage}*/}
											{/*	containerClassName={'whole-block'}*/}
											{/*	activeClassName={'paginate-active'}*/}
											{/*	disabledClassName={'paginate-disabled'}*/}
											{/*	previousLinkClassName={'previous-link'}*/}
											{/*	nextLinkClassName={'next-link'}/>*/}
										</caption>
									}
								</Table>
							</Card.Body>
						</Card>
					</Col>
				</Row>
				<Row className="mt-4">
					<Col>
						<Card className="min-h-50">
							<Card.Body>
								<Row className="align-items-center">
									<Col md="5">
										<h6 style={{ fontWeight: "bold", marginBottom: 20 }}>Invitations</h6>
										<form className="form-inline my-2 my-xl-0" onSubmit={performSearchForUnverified}>
											<input 
                                                style={{ 
                                                    borderTopRightRadius: 0, 
                                                    borderBottomRightRadius: 0, 
                                                    marginRight: '0!important' 
                                                }} 
                                                className="form-control mr-sm-2 search" 
                                                placeholder='Search unverified users' 
                                                type="search" 
                                                aria-label="Search" 
                                                value={unVerifiedSearchValue} 
                                                onChange={e => setUnverifiedSearchValue(e.target.value)} 
                                            />
											<button 
                                                style={{ 
                                                    borderTopLeftRadius: 0, 
                                                    borderBottomLeftRadius: 0 
                                                }} 
                                                className="btn btn-outline-dark my-2 my-sm-0" 
                                                type="submit"
                                            >
												<FaSearch />
											</button>
										</form>
									</Col>
									<Col md="auto" className="text-right offset-4">
										{/*<Row>
											<Col md="auto"><span className="text-muted">Sort by</span></Col>
											<Col>
												<Dropdown>
													<Dropdown.Toggle as={Link} className="text-dark font-weight-bold">
														Status-invited
													</Dropdown.Toggle>

													<Dropdown.Menu>
														<Dropdown.Item eventKey="1">1</Dropdown.Item>
														<Dropdown.Item eventKey="2">2</Dropdown.Item>
													</Dropdown.Menu>
												</Dropdown>
											</Col>
										</Row>*/}
									</Col>
								</Row>
								<Table className="mt-3" striped>
									<thead>
										<tr className="tableUnderline">
											<th>Name</th>
											<th>Email</th>
											<th>Status</th>
											<th>Role</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{unVerifiedUsers.length > 0 &&
											// unVerifiedUsers.filter(user => user.id !== currentUserId).map((user, i) =>  {
											unVerifiedUsers.map((user, i) => {
												return (
													<PendingUserTableRow
														key={i}
														profile={user.avatar || defaultAvatar}
														name={
															user.firstName &&
																user.lastName
																? `${user.firstName} ${user.lastName}`
																: "N/A"
														}
														email={user.username}
														statusStyle={inactive}
														status={convertFromVerifiedStatus(
															user,
														)}
														onDelete={() => deleteUser(user.id)}
														role={convertRole(
															user.role,
														)}
														style={approver}
													/>
												);
											})}
									</tbody>
									{
										Array.isArray(unVerifiedUsers) && unVerifiedUsers.length > 0
										&& <caption className={(unverifiedTotalPageSize <= 10) && 'first-page'}>
											<Pagination
												className="pagination-bar"
												currentPage={unverifiedCurrentPage}
												totalCount={unverifiedTotalPageSize}
												pageSize={unverifiedItemsPerPage}
												onPageChange={changeUnverifiedPage}
											/>
											{/*<ReactPaginate*/}
											{/*	previousLabel={'<'}*/}
											{/*	nextLabel={'>'}*/}
											{/*	pageCount={unverifiedPageCount}*/}
											{/*	marginPagesDisplayed={0}*/}
											{/*	pageRangeDisplayed={6}*/}
											{/*	onPageChange={changeUnverifiedPage}*/}
											{/*	containerClassName={'whole-block'}*/}
											{/*	activeClassName={'paginate-active'}*/}
											{/*	disabledClassName={'paginate-disabled'}*/}
											{/*	previousLinkClassName={'previous-link'}*/}
											{/*	nextLinkClassName={'next-link'}/>*/}
										</caption>
									}
								</Table>
							</Card.Body>
						</Card>
					</Col>
				</Row>
				{currentUserRole === 'ROLE_ADMINISTRATOR' && (
					<Row className="mt-4">
						<Col>
							<Card className="min-h-50">
								<Card.Body>
									<Row className="align-items-center">
										<Col md="5">
											<h6 style={{ fontWeight: "bold", marginBottom: 20 }}>Pending Deletions</h6>
											<form className="form-inline my-2 my-xl-0" onSubmit={performSearchForPendingDeletions}>
												<input 
													style={{ 
														borderTopRightRadius: 0, 
														borderBottomRightRadius: 0, 
														marginRight: '0!important' 
													}} 
													className="form-control mr-sm-2 search" 
													placeholder='Search pending deletions' 
													type="search" 
													aria-label="Search" 
													value={pendingDeletionsSearchValue} 
													onChange={e => setPendingDeletionsSearchValue(e.target.value)} 
												/>
												<button 
													style={{ 
														borderTopLeftRadius: 0, 
														borderBottomLeftRadius: 0 
													}} 
													className="btn btn-outline-dark my-2 my-sm-0" 
													type="submit"
												>
													<FaSearch />
												</button>
											</form>
										</Col>
									</Row>
									<Table responsive>
										<thead>
											<tr>
												<th>Name</th>
												<th>Email</th>
												<th>Role</th>
												<th>Status</th>
												<th>Actions</th>
											</tr>
										</thead>
										<tbody>
											{Array.isArray(pendingDeletions) &&
												pendingDeletions.map((user) => (
													<PendingDeletionRow key={user.id} user={user} />
												))}
										</tbody>
										{Array.isArray(pendingDeletions) && pendingDeletions.length > 0 && (
											<caption>
												<Pagination
													className="pagination-bar"
													currentPage={pendingDeletionsCurrentPage}
													totalCount={pendingDeletionsTotalPageSize}
													pageSize={10}
													onPageChange={page => setPendingDeletionsCurrentPage(page)}
												/>
											</caption>
										)}
									</Table>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				)}
				{/*<Modal*/}
				{/*	size="lg"*/}
				{/*	show={show}*/}
				{/*	aria-labelledby="contained-modal-title-vcenter"*/}
				{/*	centered*/}
				{/*	onHide={handleClose}*/}
				{/*>*/}
				{/*	<Modal.Body className="p-5">*/}
				{/*		<Row>*/}
				{/*			<Col>*/}
				{/*				<h6 className="font-weight-bold"> Invite New Users</h6>*/}
				{/*			</Col>*/}
				{/*			<Col md="auto"><FaTimes onClick={handleClose} /></Col>*/}
				{/*		</Row>*/}
				{/*		<Row className="mt-5">*/}
				{/*			<Col>*/}
				{/*				<Form>*/}
				{/*					<Form.Group controlId="formBasicEmail">*/}
				{/*						<Form.Label>Email address</Form.Label>*/}
				{/*						<InputTags values={state} onTags={(value) => setState(value.values)} />*/}
				{/*					</Form.Group>*/}

				{/*					<Form.Group controlId="formBasicPassword">*/}
				{/*						<Form.Label>Select Role</Form.Label>*/}
				{/*						<Form.Control as="select" onChange={handleRoleChange}>*/}
				{/*							<option selected value="ROLE_SUBSCRIBER_APPROVER">*/}
				{/*								Approver*/}
				{/*							</option>*/}
				{/*							<option value="ROLE_SUBSCRIBER_INDIVIDUAL">*/}
				{/*								Regular*/}
				{/*							</option>*/}
				{/*						</Form.Control>*/}
				{/*					</Form.Group>*/}

				{/*					<Button variant="danger" className="mt-4" onClick={inviteUser}>*/}
				{/*						+ Invite Users*/}
				{/*					</Button>*/}
				{/*				</Form>*/}
				{/*			</Col>*/}
				{/*		</Row>*/}
				{/*	</Modal.Body>*/}
				{/*	/!* </Modal.Body> *!/*/}
				{/*</Modal>*/}

				<>

					{/* Invite User */}
					<Modal
						size="lg"
						show={show}
						aria-labelledby="contained-modal-title-vcenter"
						centered
						onHide={handleClose}
						onEntered={() => {
							setRole('');
							setBulkRole('');
						}}
					>
						<Tabs>
							<Modal.Header closeButton style={{
								borderBottom: "none",
								display: "flex",
								height: 10,
							}}>
								<div className=""></div>
							</Modal.Header>
							<TabList style={{
								display: "flex",
								flexDirection: "row",
								padding: 10,
							}}>
								<Tab style={{ width: (currentUserRole && currentUserRole === 'ROLE_HOST') && '100%' }}>Invite User</Tab>
								<Tab style={{ display: (currentUserRole && currentUserRole === 'ROLE_HOST') ? 'none' : 'block' }}>Bulk Upload</Tab>
								{/*<Tab>Bulk Upload</Tab>*/}
							</TabList>

							<TabPanel>
								<Modal.Body>
									<div className="form-group" style={{ padding: 20 }}>
										<div
											style={{
												display: "flex",
												flexDirection: "row-reverse",
												padding: 10,
												paddingLeft: 0,
											}}
										>
											{/*<FaTimes onClick={handleClose} />*/}
											<h6 style={{ marginRight: "auto", fontSize: '1.3rem' }}>
												<b>Invite New Users</b>
											</h6>
										</div>
										<label for="" style={{ fontSize: 14 }} className="text-muted">
											Email Address
										</label>
										{/* <p>{state}</p> */}
										<div style={{ background: "white" }}>
											<div style={{ margin: 0, background: "#DDDDDD" }}>
												<InputTags
													style={{ backgroundColor: "white", fontSize: 16 }}
													values={state}
													onTags={(value) => setState(value.values)}
												/>
											</div>
											<div style={{ display: (noAddedEmail) ? 'block' : 'none', fontSize: '0.875em', marginTop: '0.25rem', color: '#dc3545' }}>Please enter an email address &lt;followed by space or enter&gt;</div>

										</div>

										<br />
										<div className='form-group'>
											<label
												style={{ fontSize: 14 }}
												className="text-muted"
												htmlFor="role"
											>
												Role
											</label>
											<select
												className={isRoleNotSelected ? "form-control is-invalid" : "form-control"}
												name="role"
												id="role"
												value={role}
												onChange={handleRoleChange}
											>
												{/*<option>Admin</option>*/}
												<option value={""} disabled>Select Role</option>
												{
													subAccountRoles &&
													subAccountRoles.map((role, index) => {
														return <option key={index} value={role.value}>{role.label}</option>;
													})
												}
												{/*<option selected defaultValue="ROLE_SUBSCRIBER_APPROVER">*/}
												{/*	Approver*/}
												{/*</option>*/}
												{/*<option value="ROLE_SUBSCRIBER_INDIVIDUAL">*/}
												{/*	Regular*/}
												{/*</option>*/}
											</select>
											<div className="invalid-feedback">Please select role</div>
										</div>

										{
											(currentUserRole && currentUserRole !== 'ROLE_HOST' && currentUserRole !== 'ROLE_ADMINISTRATOR') &&
											<div className='form-group'>
												<label
													style={{ fontSize: 14 }}
													className="text-muted"
													htmlFor="bundle"
												>
													Type of Bundle
												</label>
												<select
													className={isBundleNotSelected ? "form-control is-invalid" : "form-control"}
													name="bundle"
													id="bundle"
													value={selectedBundle}
													onChange={handleBundleChange}
												>
													{/*<option>Admin</option>*/}
													<option value={""} disabled>Select Bundle</option>
													{
														bundles &&
														bundles.map((bundle, index) => {
															const bundleType = bundle.bundleType;

															return <option key={index} value={bundleType.value}>{bundleType.label}</option>;
														})
													}
													{/*<option selected defaultValue="ROLE_SUBSCRIBER_APPROVER">*/}
													{/*	Approver*/}
													{/*</option>*/}
													{/*<option value="ROLE_SUBSCRIBER_INDIVIDUAL">*/}
													{/*	Regular*/}
													{/*</option>*/}
												</select>
												<div className="invalid-feedback">Please select role</div>
											</div>
										}

										<br />
										<div>
											<button onClick={inviteUser} className="btn btn-outline-primary" style={{
												borderRadius: "20px",
												marginRight: "20px",
											}} >
												Invite Users
											</button>
										</div>
									</div>
								</Modal.Body>
							</TabPanel>
							<TabPanel>
								<StyledModal.Body>
									<div className="form-group" style={{ padding: 20 }}>
										<div
											style={{
												display: "flex",
												padding: 10,
												paddingLeft: 0,
												alignItems: 'center',
												justifyContent: 'space-between'
											}}
										>
											<h6 style={{ fontSize: '1.3rem' }}>
												<b>Bulk Upload Users</b>
											</h6>
											<button
												type="button"
												onClick={downloadBulkUploadSample}
												className="button"
												style={{
													width: "160px",
													height: "37px",
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													whiteSpace: "nowrap"
												}}
											>
												Download sample
											</button>
										</div>
										<label htmlFor="" style={{ fontSize: 14 }} className="text-muted">
											Upload Document
										</label>
										{/* <p>{state}</p> */}
										<div className="form-group" >
											<input
												type="file"
												onChange={handleFileChange}
												style={{ padding: '5px', borderRadius: 5, border: '1px solid #DDDDDD', width: '100%' }}
												className={isInviteFileNotChosen ? "form-control form-control-file is-invalid" : "form-control form-control-file"}
												name="" id="" placeholder="" aria-describedby="fileHelpId" />
											<div className="invalid-feedback">Please select a file to upload</div>
										</div>

										<div className='form-group'>
											<label
												style={{ fontSize: 14 }}
												className="text-muted"
												htmlFor="role"
											>
												Role
											</label>
											<select
												className={isBulkRoleNotSelected ? "form-control is-invalid" : "form-control"}
												name="role"
												id="role"
												value={bulkRole}
												onChange={handleBulkRoleChange}
											>
												{/*<option>Admin</option>*/}
												<option value={''} disabled>Select Role</option>
												{
													bulkSubAccountRoles &&
													bulkSubAccountRoles.map((role, index) => {
														return <option key={index} value={role.value}>{role.label}</option>;
													})
												}
											</select>
											<div className="invalid-feedback">Please select role</div>
										</div>
										{
											(currentUserRole && currentUserRole !== 'ROLE_HOST' && currentUserRole !== 'ROLE_ADMINISTRATOR') &&
											<div className='form-group'>
												<label
													style={{ fontSize: 14 }}
													className="text-muted"
													htmlFor="bundle"
												>
													Type of Bundle
												</label>
												<select
													className={isBulkBundleNotSelected ? "form-control is-invalid" : "form-control"}
													name="bundle"
													id="bundle"
													value={bulkPackagePlan}
													onChange={handleBulkBundleChange}
												>
													{/*<option>Admin</option>*/}
													<option value={""} disabled>Select Bundle</option>
													{
														bundles &&
														bundles.map((bundle, index) => {
															const bundleType = bundle.bundleType;

															return <option key={index} value={bundleType.value}>{bundleType.label}</option>;
														})
													}
													{/*<option selected defaultValue="ROLE_SUBSCRIBER_APPROVER">*/}
													{/*	Approver*/}
													{/*</option>*/}
													{/*<option value="ROLE_SUBSCRIBER_INDIVIDUAL">*/}
													{/*	Regular*/}
													{/*</option>*/}
												</select>
												<div className="invalid-feedback">Please select role</div>
											</div>
										}
										<br />
										<div>
											<button onClick={bulkUpload} className="btn btn-outline-primary" style={{
												borderRadius: "20px",
												marginRight: "20px",
											}} >
												Invite Users
											</button>
										</div>
									</div>
								</StyledModal.Body>
							</TabPanel>
						</Tabs>
					</Modal>



					<FormModal
						title="Edit User"
						isOpen={editShow}
						isClose={handleEditClose}
						onSubmit={updateUserProfile}
						acceptButton="Update User"
						declineButton="Cancel"
						{...currentSelectedUser}
					/>

					{ /* update profile feedback modal */}
					{/* <FeedbackModall
						title="Profile Update"
						isOpen={openProfileUpdate}
						isClose={handleCloseProfileUpdate}
						doneButton="Done"
					>
						<img src={check} alt="check" />
						<h6 style={{ paddingTop: 10 }}>User profile has been updated</h6>
					</FeedbackModall>

					<FeedbackModall
						title="User Addition"
						isOpen={confirmUserAddition}
						isClose={confirmUserClose}
						doneButton="Done"
					>
						<img src={check} alt="check" />
						<h6 style={{ paddingTop: 10 }}>User invitation(s) sent</h6>
					</FeedbackModall> */}

					{/* Enable User */}
					<InformationModal
						title="Confirm Enable"
						isOpen={enableModal}
						isClose={enableUserClose}
						onSubmit={confirmEnableUser}
						acceptButton="Enable"
						declineButton="Cancel"
					>
						<div style={{ fontWeight: 400, fontSize: "1.2rem" }}>Are you sure you want to enable this User</div>
					</InformationModal>

					{/* <FeedbackModall
						isOpen={confirmEnableModal}
						isClose={() => setConfirmEnableModal(false)}
						doneButton="Okay"
					>
						<img src={check} alt="check" />
						<h6 style={{ paddingTop: 10 }}>This user has been enabled</h6>
					</FeedbackModall> */}

					{/* Disable User */}
					<InformationModal
						title="Confirm Disable"
						isOpen={disableModal}
						isClose={disableUserClose}
						onSubmit={confirmDisableUser}
						acceptButton="Disable"
						declineButton="Cancel"
					>
						<h6>Are you sure you want to disable this User</h6>
					</InformationModal>


					{/* <FeedbackModall
						isOpen={confirmDisableModal}
						isClose={() => setConfirmDisableModal(false)}
						doneButton="Okay"
					>
						<img src={check} alt="check" />
						<h6 style={{ paddingTop: 10 }}>This user has been disabled</h6>
					</FeedbackModall> */}
					{/* End of Disable User */}

					{/* Reset Password */}
					<ResetPasswordModal
						title="Confirm Reset"
						isOpen={resetPasswordModal}
						isClose={closeResetPasswordModal}
						onSubmit={confirmResetPassword}
						acceptButton="Reset Password"
						declineButton="Cancel"
						className='reset-password'
					>
						<div className={"text-primary"}
							style={{ fontSize: "1.2rem", fontWeight: 400 }} >Confirm reset password for this user</div>
					</ResetPasswordModal>

					{/* <FeedbackModall
						isOpen={confirmResetPasswordModal}
						isClose={() => setConfirmResetPasswordModal(false)}
						doneButton="Okay"
					>
						<img src={check} alt="check" />
						<h6 style={{ paddingTop: 10 }}>
							The user will be sent a password reset link
						</h6>
					</FeedbackModall> */}
					{/* End of Reset Password */}

					<InformationModal
						title="Confirm Deletion"
						isOpen={deleteshow}
						isClose={handleDeleteClose}
						onSubmit={confirmDeleteOpen}
						acceptButton="Delete"
						declineButton="Cancel"
					>
						<div>Are you sure you want to delete this User</div>
					</InformationModal>

					{/* <FeedbackModall
						isOpen={confirmDelete}
						isClose={confirmDeleteClose}
						doneButton="Okay"
					>
						<img src={check} alt="check" />
						<h6 style={{ paddingTop: 10 }}>This user has been deleted</h6>
					</FeedbackModall> */}

					<Modal show={showDeleteConfirmModal} onHide={() => setShowDeleteConfirmModal(false)}>
						<Modal.Header closeButton>
							<Modal.Title>Confirm Delete</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Are you sure you want to delete this user account? This action cannot be undone.
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={() => setShowDeleteConfirmModal(false)}>
								Cancel
							</Button>
							<Button variant="danger" onClick={confirmDeleteUser}>
								Delete
							</Button>
						</Modal.Footer>
					</Modal>

					<InformationModal
						title="Confirm Deletion"
						isOpen={showPendingDeleteModal}
						isClose={handlePendingDeleteClose}
						onSubmit={confirmPendingDeleteUser}
						acceptButton="Delete"
						declineButton="Cancel"
					>
						<div>Are you sure you want to delete this User</div>
					</InformationModal>
				</>
			</Container>
		</UserManagementStyles>
	);
};

export default UserManagement;
