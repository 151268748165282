import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Table,
  Dropdown,
  Toast,
  Modal,
  Row,
  Spinner,
  Col,
  Button,
  Image,
  Nav,
} from "react-bootstrap";
import demo from "../../assets/img/Web/Spacia/Demo.png";
import demo2 from "../../assets/img/Web/Spacia/Demo.png";
import { Link, NavLink, useHistory, useParams } from "react-router-dom";
import TableRow from "../../components/TableRow";
import prop2 from "../../assets/img/Web/Spacia/prop2.png";
import prop3 from "../../assets/img/Web/Spacia/prop3.png";
import {
  FaCaretDown,
  FaHome,
  FaLandmark,
  FaSearch,
  FaTimes,
  FaUser,
  FaRegEye,
  FaTrash,
  FaPencilAlt,
  FaImage,
} from "react-icons/fa";
import bed from "../../assets/img/Web/Spacia/bed.png";
import bath from "../../assets/img/Web/Spacia/bathtub.png";
import imgplaceholder from "../../assets/img/Web/Spacia/imgplaceholder.png";
import holder from "../../assets/img/Web/Spacia/thumb.png";
import FormModal from "../../components/NewPropertyModal";
import { FeedbackModal, BlockerModal } from "../../components/FeedbackModall";
import Filter from "../../components/Filter";
import home1 from "../../assets/img/homes/home1.jpeg";
import axios from "axios";
import moment from "moment-timezone";
import InformationModal from "../../components/informationModal";
import warning from "../../img/Web/Spacia/t-warning 3.svg";
import * as SERVICES from "../../services/localService";
import { Constants } from "../../utils/constants";
import { v4 as uuidv4 } from "uuid";
import SuccessAlert from "../../components/Settings/alerts/SuccessAlert";
import { ErrorAlert } from "../../components/Settings/alerts/ErrorAlert";
import Pagination from "../../components/Pagination";
import manage from "../../img/Web/Spacia/manage-properties.svg";
import unpublish from "../../img/Web/Spacia/unpublish.svg";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import apiService from "../../services/apiService";
// import FormControl from "@material-ui/core/FormControl";
// import Input from "@material-ui/core/Input";

// <tr>
// <td>Photo</td>
// <td>Property Info</td>
// <td>Added on</td>
// <td>Property Status</td>
// <td>Status</td>
// <td>Price</td>
// </tr>

const Properties = () => {
  let { sub } = useParams();
  console.log("ID is " + { sub });

  const [deleteItem, setDeleteItem] = useState(false);
  const [unpublishItem, setUnpublishItem] = useState(false);
  const [publishItem, setPublishItem] = useState(false);
  const [allProperties, setAllProperties] = useState([]);
  const [loading, setloading] = useState(false);
  const [currentUserRole, setCurrentUserRole] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageSize, setTotalPageSize] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [listingStatus, setListingStatus] = useState("PUBLISHED");
  const [allBlockedPropertyDates, setAllBlockedPropertyDates] = useState([]);
  const [show, setShow] = useState(false);
  const [fromManaged, setFromManaged] = useState(false);
  const [managedSuccessAlert, setManagedSuccessAlert] = useState(false);
  const [managedErrorAlert, setManagedErrorAlert] = useState(false);
  const [successMsg, setSuccessMsg] = useState(
    "Your listing has been added successfully",
  );
  const [errorMsg, setErrorMsg] = useState(
    "Your listing could not be added. Please try again!",
  );
  const [startDateObject, setStartDateObject] = useState(new Date()); // start date for a booked property initialized to the current date
  const [startDate, setStartDate] = useState("");
  const [endDateObject, setEndDateObject] = useState(new Date()); // end date for a booked property initialized to the current date
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    const now = moment(new Date()).format("yyyy-MM-DDTHH:mm");
    console.log("today is:", now);

    const startDateObject = new Date(now);
    const endDateObject = new Date(now);

    setStartDateObject(startDateObject);
    setEndDateObject(endDateObject);

    setStartDate(currentDate(startDateObject));
    setEndDate(currentDate(endDateObject));
  }, []);

  const getAllPropertiesSuccessful = (res) => {
    setAllProperties(res);
    setloading(false);
    console.log(res);
  };

  const userCurrency = SERVICES.getCurrency();
  const [successalert, setsuccessalert] = useState(false);
  const [erroralert, seterroralert] = useState(false);
  const newPropertyAlert = (data) => {
    console.log(data);
    console.log("Checking for new property");
    console.log({ sub });

    if (history.location.state && history.location.state.from == "successful") {
      setsuccessalert(true);
      // console.log("setsuccessalert(true)")
    }
    if (history.location.state && history.location.state.from == "failed") {
      seterroralert(true);
      // console.log("setsuccessalert(true)")
    }
    // if ( history.location.state && history.location.state.from == 'failed'  ) {
    // 	seterroralert(true)
    // }
    // if (sub == 0){
    // 	seterroralert(true)
    // }
    // if (sub == 2){
    // 	set(true)
    // }
    // if (sub == 0) {
    // 	console.log(sub)
    // 	seterroralert(true)
    // }
  };

  const [isCompliant, setisCompliant] = useState(false);

  // initialize current user role state
  useEffect(() => {
    const currentUser = SERVICES.getUser();
    const userRole = currentUser?.role;
    const userId = currentUser.id;

    // Clear success state if it exists on load/refresh
    if (history.location.state?.from === "successful") {
      setsuccessalert(true);
      // Clear the state immediately
      history.replace({
        pathname: "/properties",
        state: {},
      });
    }

    console.log("user id is", userId);
    console.log("current user:", currentUser);
    setCurrentUserRole(userRole);

    newPropertyAlert(SERVICES.newProperty());

    // ... rest of your existing useEffect code
  }, []);

  useEffect(() => {
    const currentUser = SERVICES.getUser();
    const userId = currentUser.id;
    const companyId = currentUser.companyId;

    apiService
      .getRegulatoryCompliance(companyId, userId)
      .then((res) => {
        console.log("Checking Compliance");
        console.log(res);
        if (res.status === 200 && res.data.data[0]) {
          setisCompliant(res.data.data[0].isCompliant);
          console.log(res.data.data[0].isCompliant);
        } else {
          setisCompliant(false);
        }
      })
      .catch(() => console.log("There was a problem checking Compliance"));
  }, []);

  // useEffect(() => {
  //     setCurrentPage(0);
  // }, [])

  useEffect(() => {
    const currentUser = SERVICES.getUser();
    const userRole = currentUser?.role;
    const userId = currentUser.id;

    console.log("user id is", userId);
    console.log("current user:", currentUser);

    newPropertyAlert(SERVICES.newProperty());

    console.log(SERVICES.newProperty());

    // Variable for currency
    // SERVICES.setCurrency('KES')

    setloading(true);

    if (userRole && userRole === "ROLE_ADMINISTRATOR") {
      apiService
        .getAllListings({
          status: listingStatus,
          page: currentPage - 1,
          size: 10,
        })
        .then((res) => {
          if (res.status === 200) {
            console.log("response for all properties:", res.data.data);

            setTotalPageSize(res.data.totalCount);
            // setTotalPageSize(500);
            setItemsPerPage(res.data.pageSize);
            getAllPropertiesSuccessful(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
          setloading(false);
        });
    } else {
      apiService
        .getUserListings(userId, {
          status: listingStatus,
          page: currentPage - 1,
        })
        .then((res) => {
          if (res.status === 200) {
            console.log("complete response:", res.data);

            setTotalPageSize(res.data.totalCount);
            // setTotalPageSize(500);
            setItemsPerPage(res.data.pageSize);
            getAllPropertiesSuccessful(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
          setloading(false);
        });
    }
  }, [currentUserRole, listingStatus]);

  const handleStartDateChange = (dateValue) => {
    console.log("current selected start date:", dateValue);
    const selectedStartDate = new Date(dateValue);
    setStartDateObject(selectedStartDate);

    const startDate = currentDate(selectedStartDate);

    console.log("current date func:", startDate);

    setStartDate(startDate);
  };

  const handleEndDateChange = (dateValue) => {
    console.log("current selected end date:", dateValue);
    const selectedEndDate = new Date(dateValue);
    setEndDateObject(selectedEndDate);

    const endDate = currentDate(selectedEndDate);

    console.log("current date func:", endDate);

    setEndDate(endDate);
  };

  const retainSelectedStartDate = (date) => {
    setStartDateObject((prevState) => {
      return prevState;
    });
  };

  const retainSelectedEndDate = (date) => {
    setEndDateObject((prevState) => {
      return prevState;
    });
  };

  function currentDate(date) {
    let selectedDate = "";

    try {
      selectedDate = date
        .toISOString()
        .substring(0, date.toISOString().lastIndexOf(":"));
    } catch (e) {
      console.error(
        "an error occurred while converting date to ISO string. message:",
        e,
      );
    }

    return selectedDate;
  }

  const [formModal, setformModal] = useState(false);
  const [showContinueListingUpdateModal, setShowContinueListingUpdateModal] =
    useState(false);
  const [deleteListingErrorMsg, setDeleteListingErrorMsg] = useState("");

  const formData = new FormData();

  let history = useHistory();

  console.log(history.location.state);

  // Form Values
  const [beds, setBeds] = useState("");
  const [baths, setBaths] = useState("");

  const [info, setInfo] = useState("");
  const [status, setStatus] = useState("Pending");
  const [price, setPrice] = useState("");
  const [denyModal, setDenyModal] = useState(false);

  const [viewModal, setViewModal] = useState(false);
  // Prefill selected modal
  const [ModalProp, setModalProp] = useState({
    id: 93,
    name: "Just added",
    amenities: [
      {
        label: "string",
      },
    ],
    description: "string",
    propertyPrice: {
      id: 96,
      price: 3000.0,
      billingPeriod: "HOURLY",
      createdOn: "2021-07-08T00:29:24.044Z",
      updatedOn: "2021-07-08T00:29:24.090Z",
    },
    location: {
      id: 95,
      latitude: "12345",
      longitude: "-12345",
      street: "50 3rd Road",
      address: "50 3rd Road",
      city: "Accra",
      country: "gh",
    },
    tags: ["string"],
    listingDetails: {
      id: 94,
      propertySize: 2,
      floorNumber: "string",
      capacity: 50,
      listingId: 93,
    },
    status: {
      ordinal: 1,
      value: "unpublished",
    },
    propertyStatus: {
      ordinal: 0,
      value: "FOR_RENT",
      label: "FOR RENT",
    },
    media: {
      images: ["home1"],
    },
    createdBy: {
      id: 2,
      username: "nicholas.akorful470@gmail.com",
      firstName: "Nick",
      lastName: "Lynx",
      name: null,
      userRole: "ROLE_CONTENT_PUBLISHER",
      isEnabled: false,
      createdOn: "2021-07-05T10:41:30.509Z",
      updatedOn: "2021-07-08T00:29:24.232Z",
    },
    createdOn: "2021-07-08T00:29:24.014Z",
    updatedOn: "2021-07-08T00:29:24.014Z",
  });

  const showViewModal = (currentProperty) => {
    let media = currentProperty.media;
    let images = media && media.images;
    let imageAtIndex0 =
      images && Array.isArray(images) && images.length > 0
        ? images[0].resourceUrl
        : imgplaceholder;

    setImage(imageAtIndex0);
    setViewModal(true);
    setModalProp(currentProperty);
    console.log(currentProperty);
  };

  const handleClose = () => {
    setManagedSuccessAlert(false);
    setManagedErrorAlert(false);

    setAllBlockedPropertyDates([]);
    setShow(false);
  };

  const closeViewModal = () => {
    setViewModal(false);
  };

  const openFormModal = () => {
    // setformModal(true)
    console.log(isCompliant);

    const currentUser = SERVICES.getUser();
    const currentUserId = currentUser ? currentUser.id : 0;

    if (isCompliant) {
      // if host is compliant, check if owner has any unfinished listing
      const storedListingData = SERVICES.getListingData();
      console.log("stored listing data available?", storedListingData != null);

      if (storedListingData) {
        // there is some data available. now check if the owner is this current user
        const storedListingOwnerId = storedListingData.ownerId;
        if (storedListingOwnerId && storedListingOwnerId === currentUserId) {
          // show 'continue adding listing' modal
          setShowContinueListingUpdateModal(true);

          return;
        }
      }

      history.push("/listproperty");
    } else {
      setDenyModal(true);
    }
  };

  const startNew = () => {
    const currentUser = SERVICES.getUser();
    const userId = currentUser ? currentUser.id : 0;

    const storedListingData = SERVICES.getListingData();
    const ownerId = storedListingData.ownerId;

    if (ownerId && userId && ownerId === userId) {
      SERVICES.clearListingData();
    }

    history.push("/listproperty");
  };

  const continueWithListingUpdates = () => {
    const storedListingData = SERVICES.getListingData();
    if (storedListingData) {
      history.push({
        pathname: "/listpropertyform",
        state: storedListingData,
      });
    }
  };

  const [currentSelectedListingId, setCurrentSelectedListingId] = useState(0);
  const deleteModal = (id) => {
    setViewModal(false);
    setCurrentSelectedListingId(id);

    setDeleteItem(true);
  };

  const unpublishModal = (id) => {
    setViewModal(false);
    setCurrentSelectedListingId(id);

    setUnpublishItem(true);
  };

  const publishModal = (id) => {
    setViewModal(false);
    setCurrentSelectedListingId(id);

    setPublishItem(true);
  };

  // useEffect(() => {
  // 	const resourceUrl = `${Constants.BASE_URL}/booking/api/v1/block-listings`;
  //
  // 	if (currentSelectedListingId !== 0) {
  // 		axios.get(resourceUrl, { params: { listingId: currentSelectedListingId}})
  // 			.then(res => {
  // 				console.log(res);
  //
  // 				setAllBlockedPropertyDates(res.data.data);
  // 			})
  // 			.catch(err => {
  // 				setAllBlockedPropertyDates([]);
  // 			})
  // 	}
  // }, [currentSelectedListingId]);

  const manageModal = (id) => {
    setCurrentSelectedListingId(id);

    apiService
      .getBlockedListingDates(id)
      .then((res) => {
        console.log(res);
        setAllBlockedPropertyDates(res.data.data);
      })
      .catch((err) => {
        setAllBlockedPropertyDates([]);
      });

    setShow(true);
  };

  const [editPropertyError, setEditPropertyError] = useState(false);

  const editModal = (id) => {
    apiService
      .getListingDetails(id)
      .then((res) => {
        console.log("Updating Local Storage");
        var editPropertyItem = res.data.data[0];
        localStorage.setItem(
          "editpropertyItem",
          JSON.stringify(editPropertyItem),
        );
        history.push(`/editproperty/${id}`);
      })
      .catch((err) => {
        setEditPropertyError(true);
        console.log(err);
      });
  };

  const closeDeleteModal = () => {
    setshowDeleteModal(false);
  };

  const onAdd = (property) => {
    console.log(property);
    setproperties([...properties, property]);
  };

  const closeFormModal = () => {
    setformModal(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!info) {
      alert("Please add a task");
      return;
    }

    setformModal(false);
    const today = new Date();
    const added =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    onAdd({ info, price, status, added, beds, baths });
    setInfo("");
  };
  const imgholder = {
    width: 100,
    height: 100,
    marginRight: 20,
    objectFit: "cover",
    display: "inline-block",
  };

  let media = ModalProp.media;
  let images = media && media.images;
  let imageAtIndex0 =
    images && Array.isArray(images) && images.length > 0
      ? images[0].resourceUrl
      : imgplaceholder;

  const [image, setImage] = useState(imageAtIndex0);

  // This is for the
  const changeImage = (e) => {
    // const temp = e.target.value
    console.log(e.target.src);
    setImage(e.target.src);
  };

  const [properties, setproperties] = useState([
    {
      id: 1,
      photo: { demo },
      info: "COMMERCIAL SPACE FOR RENT AT ACCRA OPPOSITE NIMA POLICE STATION",
      added: "1st June 2021",
      status: "active",
      price: "30",
      beds: "2",
      baths: "3",
    },
    {
      id: 2,
      photo: "{demo}",
      info: "COMMERCIAL SPACE FOR RENT AT ACCRA OPPOSITE NIMA POLICE STATION",
      added: "1st June 2021",
      status: "active",
      price: "30",
      beds: "3",
      baths: "3",
    },
  ]);

  const fit = {
    display: "flex",

    // justifyContent: 'space-evenly',
    padding: 20,
  };

  const [showDeleteModal, setshowDeleteModal] = useState(false);

  const deleteProperty = (id) => {
    const currentUser = SERVICES.getUser();
    const userId = currentUser ? currentUser.id : 0;

    apiService
      .deleteListing(id, userId)
      .then((res) => {
        console.log(res.data);
        setsuccessalert(true);
        setSuccessMsg("Your listing has been successfully deleted.");
        setAllProperties(
          allProperties.filter((property) => property.id !== id),
        );
      })
      .catch((err) => {
        console.log(err.response.data);
        const errorObj = err.response.data;
        const errorMessage = errorObj
          ? errorObj.message
          : "an error occurred while deleting listing";
        seterroralert(true);
        setErrorMsg(errorMessage);
      });

    setDeleteItem(false);
  };

  const unpublishProperty = (id) => {
    const currentUser = SERVICES.getUser();
    const userId = currentUser ? currentUser.id : 0;

    apiService
      .unpublishListing(id, userId)
      .then((res) => {
        console.log(res.data);
        setsuccessalert(true);
        setSuccessMsg("Your listing has been successfully unpublished.");
        setAllProperties(
          allProperties.filter((property) => property.id !== id),
        );
      })
      .catch((err) => {
        console.log(err.response.data);
        const errorObj = err.response.data;
        const errorMessage = errorObj
          ? errorObj.message
          : "an error occurred while deleting listing";
        seterroralert(true);
        setErrorMsg(errorMessage);
      });

    setUnpublishItem(false);
  };

  const publishProperty = (id) => {
    const currentUser = SERVICES.getUser();
    const userId = currentUser ? currentUser.id : 0;

    apiService
      .publishListing(id, userId)
      .then((res) => {
        console.log(res.data);
        setsuccessalert(true);
        setSuccessMsg("Your listing has been published successfully.");
        setAllProperties(
          allProperties.filter((property) => property.id !== id),
        );
      })
      .catch((err) => {
        console.log(err.response.data);
        const errorObj = err.response.data;
        const errorMessage = errorObj
          ? errorObj.message
          : "an error occurred while publishing listing";
        seterroralert(true);
        setErrorMsg(errorMessage);
      });

    setPublishItem(false);
  };

  const openDenyModal = () => {
    setDenyModal(true);
  };

  const changePage = useCallback((selected) => {
    const currentUser = SERVICES.getUser();
    const userRole = currentUser?.role;
    const userId = currentUser.id;

    console.log("selected page number is", selected);
    setCurrentPage(selected);

    if (userRole && userRole === "ROLE_ADMINISTRATOR") {
      apiService
        .getAllListings({
          status: listingStatus,
          page: selected - 1,
          search: searchValue,
          size: 10,
        })
        .then((res) => {
          if (res.status === 200) {
            console.log("response for all properties:", res.data.data);
            setTotalPageSize(res.data.totalCount);
            setItemsPerPage(res.data.pageSize);
            getAllPropertiesSuccessful(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
          setloading(false);
        });
    } else {
      apiService
        .getUserListings(userId, {
          status: listingStatus,
          page: selected - 1,
          search: searchValue,
        })
        .then((res) => {
          if (res.status === 200) {
            console.log("complete response:", res.data);
            setTotalPageSize(res.data.totalCount);
            setItemsPerPage(res.data.pageSize);
            getAllPropertiesSuccessful(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
          setloading(false);
        });
    }
  }, []);

  const performSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1);

    const currentUser = SERVICES.getUser();
    const userRole = currentUser?.role;
    const userId = currentUser.id;

    setloading(true);

    if (userRole && userRole === "ROLE_ADMINISTRATOR") {
      apiService
        .getAllListings({
          page: 0,
          status: listingStatus,
          search: searchValue,
          size: 10,
        })
        .then((res) => {
          if (res.status === 200) {
            console.log("response for all properties:", res.data.data);
            setTotalPageSize(res.data.totalCount);
            setItemsPerPage(res.data.pageSize);
            getAllPropertiesSuccessful(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
          setloading(false);
        });
    } else {
      apiService
        .getUserListings(userId, {
          page: 0,
          status: listingStatus,
          search: searchValue,
        })
        .then((res) => {
          if (res.status === 200) {
            console.log("complete response:", res.data);
            setTotalPageSize(res.data.totalCount);
            setItemsPerPage(res.data.pageSize);
            getAllPropertiesSuccessful(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
          setloading(false);
        });
    }
  };

  const blockPropertyWithinPeriod = () => {
    apiService
      .createBlockedListing({
        listingId: currentSelectedListingId,
        startOn: startDate,
        endOn: endDate,
      })
      .then((res) => {
        console.log(res);
        setAllBlockedPropertyDates(res.data.data);
        setManagedSuccessAlert(true);
      })
      .catch((err) => {
        setManagedErrorAlert(true);
      });
  };

  const deleteSingleEntry = (fakeBookingId) => {
    apiService
      .deleteBlockedListing(fakeBookingId)
      .then((res) => {
        console.log(res);
        setAllBlockedPropertyDates(res.data.data);
        setManagedSuccessAlert(true);
      })
      .catch((err) => {
        setManagedErrorAlert(true);
      });
  };

  const handleSelectChange = (e) => {
    setListingStatus(e.target.value);
  };

  const renderPropertyRow = () => {
    const currentUser = SERVICES.getUser();
    const currentUserRole = currentUser?.role;

    return (
      Array.isArray(allProperties) &&
      allProperties.length > 0 &&
      allProperties.map((listing) => {
        const propertyPrice = listing.propertyPrice;
        const price = propertyPrice && propertyPrice.price;
        const billingPeriod = propertyPrice && propertyPrice.billingPeriod;
        const period = {
          HOURLY: "hour",
          WEEKLY: "week",
          MONTHLY: "month",
          DAILY: "day",
        };

        let overall = "N/A";
        if (price)
          overall = billingPeriod
            ? `${price} per ${period[billingPeriod] || "year"}`
            : price;

        let createdBy = listing.createdBy;
        let firstName = (createdBy && createdBy.firstName) || "";
        let lastName = (createdBy && createdBy.lastName) || "";

        let addedBy = undefined;
        if (
          createdBy &&
          currentUserRole &&
          currentUserRole === "ROLE_ADMINISTRATOR"
        ) {
          addedBy = `${firstName} ${lastName}`;
        }

        let media = listing.media;
        let images = media && media.images;
        let imageAtIndex0 =
          images && Array.isArray(images) && images.length > 0
            ? images[0].resourceUrl
            : imgplaceholder;

        return (
          <tr key={listing.id}>
            <td
              className="cropped-cell"
              onClick={() => showViewModal(listing)}
              style={{ cursor: "pointer" }}
            >
              <div>
                <Image src={imageAtIndex0} fluid />
              </div>
            </td>
            <td
              onClick={() => showViewModal(listing)}
              style={{ cursor: "pointer" }}
            >
              <div
                className="d-flex align-items-start flex-column"
                style={{ height: 120 }}
              >
                <div className="text-truncate text-muted">{listing.name}</div>
                <div className="mt-auto">
                  <div style={{ display: "flex" }}>
                    <FaHome className="mr-1" color="grey" />
                    <h6 className="mr-4">
                      {listing.listingDetails.propertySize}
                    </h6>
                    <FaUser className="mr-1" color="grey" />
                    <h6>{listing.listingDetails.capacity}</h6>
                  </div>
                </div>
              </div>
            </td>
            <td className="text-muted">
              {moment(
                listing.propertyPrice.createdOn
                  ? listing.propertyPrice.createdOn
                  : listing.createdOn,
              ).format("DD-MMM-YYYY")}
            </td>
            {/*<td className="font-weight-bold">{listing.propertyStatus.label}</td>*/}
            {currentUserRole &&
              currentUserRole === "ROLE_ADMINISTRATOR" &&
              addedBy ? (
              <td className="text-muted">{addedBy}</td>
            ) : null}
            <td>
              <div className="d-flex flex-column" style={{ height: 120 }}>
                <div>
                  <strong className="float-right">{`${userCurrency} ${overall}`}</strong>
                </div>
                <div className="mt-auto">
                  <ul className="list-inline float-right text-small">
                    <li className="list-inline-item mr-3">
                      {currentUserRole && currentUserRole === "ROLE_HOST" ? (
                        <a
                          className="text-muted"
                          href={`${Constants.BASE_URL}/booking/api/v1/listings/${listing.id}/listing-details.html`}
                          style={{ color: "black" }}
                          target="_blank"
                          rel="noopener noreferer noreferrer"
                        >
                          <FaRegEye size="0.8em" /> View
                        </a>
                      ) : (
                        <Nav.Link
                          className="text-muted"
                          href="#"
                          onClick={() => showViewModal(listing)}
                        >
                          <FaRegEye size="0.8em" /> View
                        </Nav.Link>
                      )}
                    </li>
                    <li className="list-inline-item">
                      <Nav.Link
                        className="text-muted"
                        href="#"
                        onClick={() => deleteModal(listing.id)}
                      >
                        <FaTrash size="0.8em" /> Delete
                      </Nav.Link>
                    </li>
                    <li className="list-inline-item">
                      {listingStatus === "UNPUBLISHED" ? (
                        <Nav.Link
                          className="text-muted"
                          href="#"
                          onClick={() => publishModal(listing.id)}
                        >
                          <img
                            src={unpublish}
                            style={{ height: "1rem" }}
                            alt="manage"
                          />{" "}
                          Publish
                        </Nav.Link>
                      ) : (
                        <Nav.Link
                          className="text-muted"
                          href="#"
                          onClick={() => unpublishModal(listing.id)}
                        >
                          <img
                            src={unpublish}
                            style={{ height: "1rem" }}
                            alt="manage"
                          />{" "}
                          Unpublish
                        </Nav.Link>
                      )}
                    </li>
                    <li
                      className="list-inline-item"
                      style={{
                        display:
                          listingStatus === "UNPUBLISHED"
                            ? "none"
                            : "inline-block",
                      }}
                    >
                      <Nav.Link
                        className="text-muted"
                        href="#"
                        onClick={() => manageModal(listing.id)}
                      >
                        <img
                          src={manage}
                          style={{ height: "1rem" }}
                          alt="manage"
                        />{" "}
                        Manage
                      </Nav.Link>
                    </li>
                    <li className="list-inline-item">
                      <Nav.Link
                        className="text-muted"
                        href="#"
                        onClick={() => editModal(listing.id)}
                      >
                        <FaPencilAlt size="0.8em" /> Edit
                      </Nav.Link>
                    </li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
        );
      })
    );
  };

  return (
    <Container fluid>
      <SuccessAlert
        message={successMsg}
        show={successalert}
        close={() => setsuccessalert(false)}
      />
      <ErrorAlert
        message={errorMsg}
        show={erroralert}
        close={() => {
          seterroralert(false);
          SERVICES.setnewProperty(null);
        }}
      />

      <ErrorAlert
        message="Could not get property"
        show={editPropertyError}
        close={() => {
          setEditPropertyError(false);
          // SERVICES.setnewProperty(null)
        }}
      />

      <Row>
        <Col md="4">
          {" "}
          <h4 className="font-weight-bold">Properties</h4>{" "}
        </Col>
        <Col>
          <form className="form-inline" onSubmit={performSearch}>
            <input
              style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                marginRight: "0!important",
              }}
              className="form-control mr-sm-2 search"
              placeholder="Search"
              type="search"
              aria-label="Search"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <button
              style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              className="btn btn-outline-dark my-2 my-sm-0"
              type="submit"
            >
              <FaSearch />
            </button>
          </form>
        </Col>
        <Col md="2">
          <div className="dropdown">
            <select
              className="form-select"
              aria-label="status"
              onChange={handleSelectChange}
            >
              <option selected value="PUBLISHED">
                Published
              </option>
              <option value="PRIVATE">Private</option>
              <option value="UNPUBLISHED">Unpublished</option>
            </select>
          </div>
        </Col>
        <Col md="auto">
          {currentUserRole && currentUserRole !== "ROLE_ADMINISTRATOR" ? (
            <button
              className="button"
              type="button"
              onClick={isCompliant ? openFormModal : openDenyModal}
              style={{
                width: 'auto',
                height: "37px",
                display: "flex",
                alignItems: "center",
              }}
            >
              Add New Property{" "}
            </button>
          ) : null}
        </Col>
      </Row>

      <Row>
        <Col className="mt-4">
          <Table className="table-vtop" size="sm">
            <thead>
              <tr>
                <th>Photo</th>
                <th className="w-25">Property Info</th>
                <th>Added on</th>
                {/*<th>Property Status</th>*/}
                {currentUserRole && currentUserRole === "ROLE_ADMINISTRATOR" ? (
                  <th>Added by</th>
                ) : null}
                <th className="text-right">Price</th>
              </tr>
            </thead>
            <tbody>{renderPropertyRow()}</tbody>
          </Table>
          {Array.isArray(allProperties) && allProperties.length > 0 && (
            <Pagination
              className="pagination-bar float-right"
              currentPage={currentPage || 1}
              totalCount={totalPageSize}
              pageSize={10}
              onPageChange={changePage}
            />
          )}
        </Col>
      </Row>

      <Modal
        show={showContinueListingUpdateModal}
        onHide={() => setShowContinueListingUpdateModal(false)}
        style={{ textAlign: "center" }}
      >
        <Modal.Body
          style={{ width: "85%", margin: "0 auto", padding: "40px 0" }}
        >
          <div
            style={{
              fontSize: "1.21rem",
              fontWeight: "bold",
              marginBottom: "30px",
            }}
          >
            <p>You have an unfinished listing</p>
            <p>Do you want to continue?</p>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button onClick={startNew} className="button-calm">
              Start New
            </button>
            <button onClick={continueWithListingUpdates} className="button">
              Continue
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        scrollable={true}
        show={viewModal}
        onHide={closeViewModal}
        size="xl"
      >
        <Modal.Header
          className="border-0 d-block"
          style={{
            borderBottom: "none",
            paddingLeft: "30px",
            paddingBottom: 0,

            paddingRight: "30px",
            margin: "0px",
          }}
          closeButton
        >
          <section className="mt-3 mb-1 px-4">
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h2 className="font-weight-normal">
                    {ModalProp.name}
                    {/*Mediterranean Garden Villa with Outdoor Pool*/}
                  </h2>
                </div>
                <div>
                  <div className="rounded-pill category-button">
                    <p className="py-2 px-4">{ModalProp.category}</p>
                  </div>
                </div>
              </div>
              <div className="my-3">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    {/*<div className="rounded-pill rent-button me-3">*/}
                    {/*	<p className=" py-2 px-4">{ModalProp.propertyStatus.label}</p>*/}
                    {/*</div>*/}
                    <div>
                      <p className="mx-3 text-success">
                        <i className="material-icons inline-icon mr-2">
                          verified
                        </i>
                        Approved
                      </p>
                    </div>
                    .
                    <div>
                      <p className="mx-3">{ModalProp.subCategory}</p>
                    </div>
                    .
                    <div>
                      <p className="mx-3 text-muted">
                        {ModalProp.location.address}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className=" ms-3">
                      <p className="">
                        <i className="material-icons inline-icon">share</i>{" "}
                        Share
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Modal.Header>
        <Modal.Body>
          <section className="mb-5 px-4">
            <div className="">
              <div>
                <div className="">
                  <div className="d-flex align-items-stretch detail-images-container">
                    <div className="image-large-container">
                      <div className="mr-3">
                        <img
                          src={ModalProp.media ? image : demo}
                          className="detail-image-dimensions detail-image"
                        />
                      </div>
                    </div>
                    <div className="image-siblings-container">
                      <div>
                        {ModalProp.media &&
                          ModalProp.media.images &&
                          ModalProp.media.images.map((image) => {
                            return (
                              <div className=" mb-2">
                                <img
                                  onClick={changeImage}
                                  className="sibling-images"
                                  src={image.resourceUrl}
                                  alt="placeholder"
                                />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="mb-5 px-4">
            <div>
              <div className="d-flex">
                <div className="description-section mr-4">
                  <section>
                    <div>
                      <section>
                        <div>
                          <h3 className="font-weight-normal">
                            {ModalProp.subCategory} listed by{" "}
                            {ModalProp.createdBy.firstName}{" "}
                            {ModalProp.createdBy.lastName}
                          </h3>
                        </div>
                        <div>
                          <div className="d-flex my-2">
                            <div className="mr-3">
                              <h5 className="font-weight-normal text-muted">
                                Floor {ModalProp.listingDetails.floorNumber}
                              </h5>
                            </div>
                            .
                            <div className="mx-3">
                              <h5 className="font-weight-normal text-muted">
                                Capacity {ModalProp.listingDetails.capacity}
                              </h5>
                            </div>
                            <div className="mx-3">
                              <h5 className="font-weight-normal text-muted">
                                Property Size{" "}
                                {ModalProp.listingDetails.propertySize}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </section>
                      <hr />
                      <section>
                        <div>
                          <div className="my-4">
                            <h5 className="font-weight-normal">Description</h5>
                          </div>
                          <div>
                            <p>{ModalProp.description}</p>
                          </div>
                        </div>
                      </section>
                      <hr className="mt-5" />
                      <section>
                        <div>
                          <div className="my-4">
                            <h5 className="font-weight-normal">Amenities</h5>
                          </div>
                          <div className="d-flex flex-wrap">
                            {ModalProp.amenities.map((amenity) => {
                              return (
                                <div className=" mr-2 mb-3">
                                  <div className="">
                                    <div className="rounded-pill bg-gray py-2 px-4">
                                      {amenity.label}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </section>
                    </div>
                  </section>
                </div>
                <div className="price-section ml-3">
                  <section>
                    <div className="card curved shadow-sm">
                      <div className="card-body">
                        <div>
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <h5 className="font-weight-normal">
                                KHS {ModalProp.propertyPrice.price}
                              </h5>
                            </div>
                            <div>{ModalProp.propertyPrice.billingPeriod}</div>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
        </Modal.Body>
      </Modal>

      {/* manage properties modal */}
      <Modal
        show={show}
        onHide={handleClose}
        contentClassName="padding-40px"
        style={{
          overflow: "hidden",
          borderRadius: "30px",
        }}
        className="manage-props-modal"
      >
        <SuccessAlert
          message={successMsg}
          show={managedSuccessAlert}
          close={() => setManagedSuccessAlert(false)}
        />
        <ErrorAlert
          message={errorMsg}
          show={managedErrorAlert}
          close={() => setManagedErrorAlert(false)}
        />
        <Modal.Header
          style={{
            borderBottom: "none",
            paddingLeft: "30px",
            paddingBottom: 0,

            paddingRight: "30px",
            margin: "0px",
          }}
          closeButton
        >
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ width: "100%", textAlign: "center" }}
          >
            {/* <h6 className="text-primary">Confirm Payment</h6> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container
            className="text-center position-relative"
            style={{ bottom: "15px" }}
          >
            <div>
              <div
                className={"text-primary mb-3"}
                style={{ fontSize: "1.3rem", fontWeight: 200 }}
              >
                Manage Listing
              </div>
            </div>
            <Row
              style={{
                margin: "0",
                justifyContent: "space-between",
                alignItems: "",
              }}
            >
              <Col
                md={6}
                className="calendar"
                style={{ padding: "0", width: "35%", flexGrow: "0" }}
              >
                <p style={{ marginBottom: "2px", fontWeight: "500" }}>Start</p>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={(params) => <TextField {...params} />}
                    value={startDateObject}
                    onChange={(newValue) => {
                      handleStartDateChange(newValue);
                    }}
                  // onClose={() => handleStartDateChange(startDateObject)}
                  />
                </LocalizationProvider>
              </Col>
              <Col
                md={6}
                className="calendar"
                style={{ padding: "0", width: "35%", flexGrow: "0" }}
              >
                <p style={{ marginBottom: "2px", fontWeight: "500" }}>End</p>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={(params) => <TextField {...params} />}
                    value={endDateObject}
                    onChange={(newValue) => {
                      handleEndDateChange(newValue);
                    }}
                  // onClose={() => handleEndDateChange(endDateObject)}
                  />
                </LocalizationProvider>
              </Col>
              {/* <Col style={{display: 'flex', alignItems: 'flex-end', padding: '0', width: '21%', flexGrow: '0'}}> */}

              {/* </Col> */}
            </Row>
            <button
              type="button"
              className="btn btn-outline-primary mt-3"
              style={{
                borderRadius: "20px",
                marginLeft: "0px",
                width: "8rem",
              }}
              onClick={blockPropertyWithinPeriod}
            >
              Block
            </button>
            <div>
              <p
                className={"text-primary mt-3"}
                style={{ fontSize: "1rem", fontWeight: 400 }}
              >
                {allBlockedPropertyDates.length > 0
                  ? "Blocked out dates for this listing"
                  : "No blocked out dates for this listing"}
              </p>
              <ul style={{ maxHeight: "220px", overflowY: "scroll" }}>
                {Array.isArray(allBlockedPropertyDates) &&
                  allBlockedPropertyDates.length > 0 &&
                  allBlockedPropertyDates.map((blocked, index) => {
                    console.log("blocked:", blocked);

                    const startOn = blocked.startOn;
                    const endOn = blocked.endOn;

                    const fakeBookingId = blocked.fakeBookingId;

                    return (
                      <li
                        key={uuidv4()}
                        className="manage-properties-list"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "10px 5px",
                          borderBottom: "1px solid black",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          {/*<p>{(index + 1)}. </p>&nbsp;*/}
                          <p style={{ fontSize: "1.12rem" }}>
                            {moment(startOn).format("MMMM DD, YYYY HH:mm")}{" "}
                            &nbsp; to &nbsp;
                            {moment(endOn).format("MMMM DD, YYYY HH:mm")}
                          </p>
                        </div>
                        <div>
                          <FaTimes
                            onClick={() => deleteSingleEntry(fakeBookingId)}
                          />
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </Container>
        </Modal.Body>
      </Modal>

      {/* doneButton={() => deleteProperty()} */}
      <FormModal
        onSubmit={onSubmit}
        title="Add a new property"
        isOpen={formModal}
        isClose={closeFormModal}
        declineButton="Cancel"
        acceptButton="Change"
        info={info}
        setInfo={setInfo}
        status={status}
        setStatus={setStatus}
        beds={beds}
        setBeds={setBeds}
        price={price}
        setPrice={setPrice}
        baths={baths}
        setBaths={setBaths}
      />
      <FeedbackModal
        isClose={closeDeleteModal}
        title="Delete property"
        doneButton="Okay"
        isOpen={showDeleteModal}
        declineButton={closeDeleteModal}
      >
        <div
          className={"text-primary"}
          style={{ fontSize: "1.2rem", fontWeight: 400 }}
        >
          This property has been deleted
        </div>
      </FeedbackModal>

      <InformationModal
        onSubmit={() => deleteProperty(currentSelectedListingId)}
        title="Delete property"
        acceptButton="Yes"
        declineButton="No"
        isOpen={deleteItem}
        isClose={() => setDeleteItem(false)}
      >
        <div
          className={"text-primary"}
          style={{ fontSize: "1.2rem", fontWeight: 400 }}
        >
          Are you sure you want to delete this property
        </div>
      </InformationModal>

      <InformationModal
        onSubmit={() => unpublishProperty(currentSelectedListingId)}
        title="Unpublish property"
        acceptButton="Yes"
        declineButton="No"
        isOpen={unpublishItem}
        isClose={() => setUnpublishItem(false)}
      >
        <div
          className={"text-primary"}
          style={{ fontSize: "1.2rem", fontWeight: 400 }}
        >
          Are you sure you want to unpublish this property
        </div>
      </InformationModal>

      <InformationModal
        onSubmit={() => publishProperty(currentSelectedListingId)}
        title="Publish property"
        acceptButton="Yes"
        declineButton="No"
        isOpen={publishItem}
        isClose={() => setPublishItem(false)}
      >
        <div
          className={"text-primary"}
          style={{ fontSize: "1.2rem", fontWeight: 400 }}
        >
          Are you sure you want to publish this property
        </div>
      </InformationModal>

      <BlockerModal
        title="Sorry"
        isOpen={denyModal}
        isClose={() => setDenyModal(false)}
        onSubmit={() => history.push("/settings/company/profile")}
        doneButton="  Settings  "
      >
        <h6 style={{ fontWeight: 300 }} className="text-muted">
          Whoa! Please go to company profile under settings and add required
          documents before you can add a listing
        </h6>
        <br />
        <img src={warning} alt="warning" />
      </BlockerModal>
    </Container>
  );
};

export default Properties;
