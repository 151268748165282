import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SERVICES from '../../services';

declare global {
    interface Window {
        flutter_inappwebview?: {
            callHandler(handlerName: string, ...args: any[]): void;
        };
    }
}

const PaymentRedirect = () => {
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const reference = queryParams.get("reference");
        const trxref = queryParams.get("trxref");

        const source = localStorage.getItem("paymentSource");
        const paymentType = localStorage.getItem("paymentType");
        console.log("Payment redirect - Source:", source, "Type:", paymentType);

        let redirectPath;

        // Handle both mobile web and desktop redirects
        if (source === 'booking-mobile') {
            redirectPath = '/reservation';
        } else if (source === 'booking') {
            redirectPath = '/reservations';
        } else if (source === 'membership-plan') {
            redirectPath = '/membership-plan';
        } else if (source === 'package-signup') {
            redirectPath = '/signin';
        } else {
            console.log("Unknown payment source:", source);
            redirectPath = '/signin';
        }

        console.log("Payment redirect - Redirect path:", redirectPath);

        // Handle mobile app webview
        if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler('PaymentComplete', {
                reference,
                trxref,
                redirectPath,
                paymentSource: source
            });
            return;
        }

        // Handle browser window
        if (window.opener) {
            console.log("Payment redirect - Sending message to parent window");
            
            // First send the message to the parent
            window.opener.postMessage({
                type: 'PAYMENT_COMPLETE',
                redirectPath,
                paymentSource: source,
                reference,
                trxref
            }, '*');

            // Give the parent window time to receive and process the message
            setTimeout(() => {
                console.log("Payment redirect - Closing payment window");
                window.close();
            }, 2000);
            return;
        }

        // Handle same window (no popup)
        console.log("Payment redirect - Same window redirect to:", redirectPath);
        history.push(redirectPath);
        
    }, [history, location]);

    return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
            <div className="text-center">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <p className="mt-3">Payment successful! Redirecting...</p>
            </div>
        </div>
    );
};

export default PaymentRedirect;