import { Link, useHistory } from "react-router-dom";
import React, { FormEvent, useEffect, useState } from "react";
import { User } from "../../../../../utils/interfaces";
import SERVICES from "../../../../../services";
import { toAbsoluteUrl } from "../../../../../layout/helpers";
import { LogoFacebook, LogoInstagram, LogoTwitter } from "react-ionicons";
import {
    FaRegEye as FaEye,
    FaRegEyeSlash as FaEyeSlash,
    FaRegWindowClose,
} from "react-icons/fa";
import { Constants } from "../../../../../utils/constants";
import { ErrorAlert } from "../../../../../components/Settings/alerts/ErrorAlert";
import Footer from "../../../../../footer/footer";
// import { toast, ToastContainer, cssTransition } from "react-toastify";
import { SnackbarProvider, useSnackbar, OptionsObject, closeSnackbar } from 'notistack';
import PhoneInput from 'react-phone-number-input';
import apiService from '../../../../../services/apiService';

const PackageSignup = () => {
    const history = useHistory();

    const [errorModal, setErrorModal] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {
        // Set payment source when component mounts
        localStorage.setItem('paymentType', 'package');
        localStorage.setItem('paymentSource', 'package-signup');
    }, []);

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [organizationName, setOrganizationName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState<string>("");
    const [organizationSize, setOrganizationSize] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [role, setRole] = useState("ROLE_SUBSCRIBER_OWNER");
    const [isLoading, setIsLoading] = useState(false);

    const [passwordValidationError, setPasswordValidationError] = useState("");
    const [confirmPasswordValidationError, setConfirmPasswordValidationError] = useState("");

    const { enqueueSnackbar } = useSnackbar();

    const callErrorToast = (message: string) => {
        const options: OptionsObject = {
            variant: 'default',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
            autoHideDuration: 3000,
            action: (key) => (
                <div style={{ display: 'flex', alignItems: 'center', paddingTop: "20px", paddingRight: "20px", paddingBottom: "20px", fontSize: "16px", minWidth: "300px", justifyContent: "space-between" }}>
                    <span style={{ marginRight: '10px', color: 'red' }}>{message}</span>
                    <FaRegWindowClose
                        onClick={() => {
                            // Close the snackbar using the key
                            closeSnackbar(key);
                        }}
                        style={{
                            width: '20px',
                            height: '20px',
                            fontSize: '20px',
                            color: 'red',
                            cursor: 'pointer',
                        }}
                    />
                </div>
            ),
            style: {
                backgroundColor: '#FFDDDD', // Light red background
            },
        };

        enqueueSnackbar('', options);
    };

    const validatePassword = (pass: string) => {
        if (!pass) {
            setPasswordValidationError("Password is required");
            setIsEmptyPassword(true);
            return false;
        }
        if (pass.length < 7) {
            setPasswordValidationError("Password must be at least 7 characters");
            setIsEmptyPassword(true);
            return false;
        }
        setPasswordValidationError("");
        setIsEmptyPassword(false);
        return true;
    };

    const validateConfirmPassword = (pass: string, confirmPass: string) => {
        if (!confirmPass) {
            setConfirmPasswordValidationError("Confirm password is required");
            setIsInvalidConfirmPassword(true);
            return false;
        }
        if (pass !== confirmPass) {
            setConfirmPasswordValidationError("Passwords do not match");
            setIsInvalidConfirmPassword(true);
            return false;
        }
        setConfirmPasswordValidationError("");
        setIsInvalidConfirmPassword(false);
        return true;
    };

    useEffect(() => {
        if (password) {
            validatePassword(password);
        }
        if (confirmPassword) {
            validateConfirmPassword(password, confirmPassword);
        }
    }, [password, confirmPassword]);

    const [isEmptyFirstName, setIsEmptyFirstName] = useState(false);
    const [isEmptyLastName, setIsEmptyLastName] = useState(false);
    const [isEmptyEmail, setIsEmptyEmail] = useState(false);
    const [isEmptyPassword, setIsEmptyPassword] = useState(false);
    const [isInvalidConfirmPassword, setIsInvalidConfirmPassword] = useState(false);
    const [isInvalidPhoneNumber, setIsInvalidPhoneNumber] = useState(false);
    const [isEmptyOrganizationName, setIsEmptyOrganizationName] = useState(false);
    const [isEmptyOrganizationSize, setIsEmptyOrganizationSize] = useState(false);

    const [phoneNumberValidationError, setPhoneNumberValidationError] = useState("");

    const togglePasswordVisibility = (e: any) => {
        e.preventDefault();

        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = (e: any) => {
        e.preventDefault();

        setShowConfirmPassword(!showConfirmPassword);
    };

    const isLoggedIn = (user: User) => {
        return user != null && Object.keys(user).length !== 0;
    };

    const routeTo = (path: string) => {
        history.push(path);
    };

    const redirectBasedOnRole = () => {
        const currentUser = SERVICES.getUser() as User;
        let destination = "/startBooking";

        if (!isLoggedIn(currentUser)) {
            destination = "/signin";
        } else {
            const role = currentUser.role;

            switch (role) {
                case "ROLE_ADMINISTRATOR":
                    destination = "/dashboard";
                    break;

                case "ROLE_SUBSCRIBER_OWNER":
                    destination = "/startBooking";
                    break;

                default:
                    destination = "/properties";
            }
        }

        return destination;
    };

    function hasText(text: string) {
        return text != null && text !== "";
    }

    useEffect(() => {
        if (hasText(firstName)) {
            setIsEmptyFirstName(false);
        }
        if (hasText(lastName)) {
            setIsEmptyLastName(false);
        }
        if (hasText(email)) {
            setIsEmptyEmail(false);
        }
        if (hasText(password)) {
            setIsEmptyPassword(false);
        }
        if (hasText(confirmPassword)) {
            setIsInvalidConfirmPassword(false);
        }
        if (password && !confirmPassword) {
            setIsInvalidConfirmPassword(false);
        }
        if (mobile) {
            setIsInvalidPhoneNumber(false);
        }
        if (organizationName) {
            setIsEmptyOrganizationName(false);
        }
        if (organizationSize) {
            setIsEmptyOrganizationSize(false);
        }
    }, [
        firstName,
        lastName,
        email,
        password,
        confirmPassword,
        mobile,
        organizationName,
        organizationSize,
    ]);

    const areFieldsOkay = () => {
        const requiredFields = [
            firstName,
            lastName,
            email,
            password,
            confirmPassword,
            mobile,
            organizationName,
            organizationSize,
        ];

        // Check all required fields
        const anyEmpty = requiredFields.some((field) => !field);
        if (anyEmpty) {
            if (!hasText(firstName)) {
                setIsEmptyFirstName(true);
            }
            if (!hasText(lastName)) {
                setIsEmptyLastName(true);
            }
            if (!hasText(email)) {
                setIsEmptyEmail(true);
            }
            if (!mobile) {
                setIsInvalidPhoneNumber(true);
                setPhoneNumberValidationError("Please enter mobile number");
            }
            if (!organizationName) {
                setIsEmptyOrganizationName(true);
            }
            if (!organizationSize) {
                setIsEmptyOrganizationSize(true);
            }
            return false;
        }

        // Validate password requirements
        const isPasswordValid = validatePassword(password);
        const isConfirmPasswordValid = validateConfirmPassword(password, confirmPassword);

        return isPasswordValid && isConfirmPasswordValid;
    };

    const submitSignup = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let userData = {
            avatar: "https://storage.googleapis.com/maestro-8beff.appspot.com/user-543-_1626834155761_69ec79f5_1b40_4716_9958_1a6f557f6581.jpeg",
            companyId: 1,
            confirmPassword: confirmPassword,
            contacts: [
                {
                    isContactable: true,
                    isPrimary: true,
                    type: "mobile",
                    value: mobile,
                },
            ],
            firstName,
            lastName,
            password: password,
            username: email,
            role,
            organizationName,
            organizationSize,
        };

        console.log(
            firstName,
            lastName,
            email,
            password,
            confirmPassword,
            role,
            organizationName,
            organizationSize
        );

        setIsLoading(true);

        if (!areFieldsOkay()) {
            console.log("something went wrong");
            setIsLoading(false);
            return;
        }

        apiService.signup(userData)
            .then((res) => {
                if (res.status === 201) {
                    const response = res.data;
                    const responseData = response?.data;

                    // @ts-ignore
                    const userAfterSignup =
                        responseData &&
                            Array.isArray(responseData) &&
                            responseData.length > 0
                            ? responseData[0]
                            : {};

                    console.log("response after signup:", responseData);
                    console.log("user after signup:", userAfterSignup);

                    localStorage.setItem(
                        "userAfterSignup",
                        JSON.stringify(userAfterSignup)
                    );
                    localStorage.setItem("organizationSize", organizationSize);

                    const username = userAfterSignup
                        ? userAfterSignup.username
                        : "";

                    const payload = `{"username": "${username}"}`;

                    const encodedString = btoa(payload);

                    history.push(`/otp-confirmation?payload=${encodedString}`);
                }
            })
            .catch((err) => {
                const {
                    response: {
                        data: { errors },
                    },
                } = err;
                console.log(errors);

                setErrorModal(true);

                if (errors && Array.isArray(errors) && errors.length > 0) {
                    const { message } = errors[0];
                    setErrorMsg(message);
                    callErrorToast(message);
                }
            }).finally(() => {
                setIsLoading(false);
            });;
    };

    return (
        <div id="appCapsule" className="pkg-signup">
            {/* <ToastContainer style={{}} /> */}
            <div className={"gradient"} role="presentation" />
            {/*Main Section*/}
            <section className={"main-section py-5"}>
                <header className={"text-center main-section-header pt-5"}>
                    <h1 style={{ fontSize: "1.5rem" }}>Lets set you up!</h1>
                    <p className={"text-dark"} style={{ fontSize: "0.85rem" }}>
                        Already have an account? &nbsp;
                        <span
                            style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                            }}
                            onClick={() => routeTo("/signin")}
                        >
                            Log in
                        </span>
                    </p>
                </header>
                <form onSubmit={submitSignup}>
                    <div className={"name form-group"}>
                        <label>
                            First name <br />
                        </label>
                        <input
                            type="text"
                            className={
                                isEmptyFirstName
                                    ? "form-control form-control-md border border-dark is-invalid"
                                    : "form-control form-control-md border-dark"
                            }
                            autoComplete="off"
                            placeholder={"Enter your first name"}
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            style={{
                                backgroundColor: "white",
                                padding: "10px",
                            }}
                        />

                        <div className="invalid-feedback">
                            This field can't be empty
                        </div>
                    </div>
                    <div className={"name form-group"}>
                        <label>
                            Last name <br />
                        </label>
                        <input
                            type="text"
                            className={
                                isEmptyLastName
                                    ? "form-control form-control-md border " +
                                    "border-dark is-invalid"
                                    : "form-control form-control-md border border-dark"
                            }
                            autoComplete="off"
                            placeholder={"Enter your last name"}
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            style={{
                                backgroundColor: "white",
                                padding: "10px",
                            }}
                        />
                        <div className="invalid-feedback">
                            This field can't be empty
                        </div>
                    </div>
                    <div className={"org-name form-group"}>
                        <label>
                            Organization Name <br />
                        </label>
                        <input
                            type="text"
                            className={
                                isEmptyOrganizationName
                                    ? "form-control form-control-md border " +
                                    "border-dark is-invalid"
                                    : "form-control form-control-md border border-dark"
                            }
                            autoComplete="off"
                            placeholder={"Enter your Organization Name"}
                            value={organizationName}
                            onChange={(e) =>
                                setOrganizationName(e.target.value)
                            }
                            style={{
                                backgroundColor: "white",
                                padding: "10px",
                            }}
                        />
                        <div className="invalid-feedback">
                            Organization name is required.
                        </div>
                    </div>
                    <div className={"email form-group"}>
                        <label>
                            Email <br />
                        </label>
                        <input
                            type="email"
                            className={
                                isEmptyEmail
                                    ? "form-control form-control-md border " +
                                    "border-dark is-invalid"
                                    : "form-control form-control-md border border-dark"
                            }
                            autoComplete="off"
                            placeholder={"Enter your email address"}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            style={{
                                backgroundColor: "white",
                                padding: "10px",
                            }}
                        />
                        <div className="invalid-feedback">
                            Please enter your email
                        </div>
                    </div>
                    <div className={"msisdn form-group"}>
                        <label>
                            Phone number <br />
                        </label>
                        <PhoneInput
                            international
                            defaultCountry="KE"
                            value={mobile}
                            onChange={(value: string | undefined) => setMobile(value || "")}
                            className={
                                isInvalidPhoneNumber
                                    ? "form-control is-invalid"
                                    : "form-control"
                            }
                            style={{
                                backgroundColor: '#F8F9FA'
                            }}
                        />
                        <div className="invalid-feedback">
                            Please enter your phone number
                        </div>
                    </div>
                    <div className={"org-size form-group"}>
                        <label>
                            Team Size (Number of people the package will serve)<br />
                        </label>
                        <input
                            type="number"
                            min="5"
                            className={
                                isEmptyOrganizationSize
                                    ? "form-control form-control-md border " +
                                    "border-dark is-invalid"
                                    : "form-control form-control-md border border-dark"
                            }
                            autoComplete="off"
                            placeholder={"Number of People"}
                            value={organizationSize}
                            onChange={(e) =>
                                setOrganizationSize(e.target.value)
                            }
                            style={{
                                backgroundColor: "white",
                                padding: "10px",
                                // WebkitAppearance: "none",
                                // MozAppearance: "textfield",
                                // appearance: "textfield",
                            }}
                            onWheel={(e) => e.currentTarget.blur()}
                            onKeyDown={(e) => {
                                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                                    e.preventDefault();
                                }
                            }}
                        />
                        <div className="invalid-feedback">
                            This field is required.
                        </div>
                    </div>
                    <div className={"password form-group"}>
                        <label>
                            <div className={"d-flex justify-content-between"}>
                                <p>Create a password</p>
                                <div className={"d-flex"}>
                                    <a
                                        onClick={togglePasswordVisibility}
                                        style={{
                                            color: "#000000",
                                        }}
                                    >
                                        <i>
                                            {showPassword ? (
                                                <FaEye />
                                            ) : (
                                                <FaEyeSlash />
                                            )}
                                        </i>
                                    </a>
                                    <p className={"ms-1"}>Hide</p>
                                </div>
                            </div>
                        </label>
                        <input
                            type={showPassword ? "text" : "password"}
                            className={
                                isEmptyPassword
                                    ? "form-control form-control-md border " +
                                    "border-dark is-invalid"
                                    : "form-control form-control-md border border-dark"
                            }
                            autoComplete="off"
                            placeholder={"Enter your password"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            style={{
                                backgroundColor: "white",
                                padding: "10px",
                            }}
                        />
                        {passwordValidationError && (
                            <div className="invalid-feedback">{passwordValidationError}</div>
                        )}
                        <p style={{ fontSize: ".8rem", marginBottom: "-10px" }}>
                            Use 8 or more characters with a mix of letters,
                            numbers &amp; symbols
                        </p>
                    </div>
                    <div className={"password form-group"}>
                        <label>
                            <div className={"d-flex justify-content-between"}>
                                <p>Confirm your password</p>
                                <div className={"d-flex"}>
                                    <a
                                        onClick={
                                            toggleConfirmPasswordVisibility
                                        }
                                        style={{
                                            color: "#000000",
                                        }}
                                    >
                                        <i>
                                            {showConfirmPassword ? (
                                                <FaEye />
                                            ) : (
                                                <FaEyeSlash />
                                            )}
                                        </i>
                                    </a>
                                    <p className={"ms-1"}>Hide</p>
                                </div>
                            </div>
                        </label>
                        <input
                            type={showConfirmPassword ? "text" : "password"}
                            className={
                                isInvalidConfirmPassword
                                    ? "form-control form-control-md border " +
                                    "border-dark is-invalid"
                                    : "form-control form-control-md border border-dark"
                            }
                            autoComplete="off"
                            placeholder={"Enter your password"}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            style={{
                                backgroundColor: "white",
                                padding: "10px",
                            }}
                        />
                        {confirmPasswordValidationError && (
                            <div className="invalid-feedback">{confirmPasswordValidationError}</div>
                        )}
                    </div>
                    <div className={"agree-to-terms"}>
                        <p className={"text-dark"} style={{ marginBottom: "20px" }}>
                            By creating an account, you agree to the{" "}
                            <a
                                href={process.env.PUBLIC_URL + '/assets/terms_conditions.pdf'}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    textDecoration: "underline",
                                    color: '#000000',
                                    fontWeight: 500,
                                    borderBottom: '1px solid #000000',
                                    paddingBottom: '2px'
                                }}
                            >
                                terms & conditions
                            </a>{" "}
                            for the service.
                        </p>
                        <button type="submit" className="btn btn-primary w-100">
                            {isLoading ? (
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            ) : (
                                "Create Account"
                            )}
                        </button>
                    </div>
                </form>
            </section>

            <div className={"gradient"} role="presentation" />
            {/*Footer*/}
            <Footer />

            <style>{`
                .PhoneInput {
                    background-color: #F8F9FA00 !important;
                    height: 38px !important;
                }

                .PhoneInput .PhoneInputInput {
                    background-color: #F8F9FA00 !important;
                    border: none !important;
                    padding: 0.35rem 0.3rem;
                    height: 38px !important;
                    line-height: 38px !important;
                }

                .PhoneInput .PhoneInputCountry {
                    padding-right: 0.2rem;
                    padding-left: 0.5rem;
                    height: 38px !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .form-control.PhoneInput {
                    height: 38px !important;
                    padding: 0 !important;
                }
            `}</style>
        </div>
    );
};

export default PackageSignup;
