import { FaChevronRight, FaQuestionCircle } from "react-icons/fa";
import { useState } from "react";
import { Nav, Button, Collapse, Image } from "react-bootstrap";
import { Constants } from "../../utils/constants";
import logo from "../../assets/img/spacia/web/spacia_logo_new.png";
import { useLocation, useHistory, NavLink } from "react-router-dom";

export const SideNav = (props) => {
    //setStates to handle dropdown
    const [openMenus, setOpenMenus] = useState({});
    const location = useLocation();
    const history = useHistory();

    const gotToHome = () => {
        history.push("/home");
    };

    const toggleMenu = (menuIndex) => {
        setOpenMenus(prev => ({
            ...prev,
            [menuIndex]: !prev[menuIndex]
        }));
    };

    const renderMenus = () => {
        return props.currentUserRole
            ? Constants.getMenusByRole(props.currentUserRole).map((e, i) => {
                return e.children ? (
                    <div key={i} className={"parent-menu-item"}>
                        <Button
                            onClick={() => toggleMenu(i)}
                            aria-controls={`menu-collapse-${i}`}
                            aria-expanded={openMenus[i]}
                            variant="link"
                            className="pl-2 d-flex align-items-center nav-link"
                        >
                            <img
                                src={`../../assets/img/Web/Spacia/${e.icon}`}
                                alt={e.route}
                                style={{ height: '20px', width: '20px' }}
                            />{" "}
                            &nbsp;&nbsp; {e.name} &nbsp; &nbsp;
                            <FaChevronRight
                                className="float-end"
                                style={{
                                    height: '10px',
                                    width: '10px',
                                    transition: 'transform 0.2s',
                                    transform: openMenus[i] ? 'rotate(90deg)' : 'rotate(0deg)',
                                }}
                            />
                        </Button>
                        <Collapse
                            in={openMenus[i] || e.children.some(
                                (subMenu) => subMenu.route === location.pathname
                            )}
                            id={`menu-collapse-${i}`}
                        >
                            <Nav
                                defaultActiveKey={location.pathname}
                                className="flex-column sidebar-sub-menu ml-0 my-0 pr-0 "
                                style={{ display: openMenus[i] ? 'block' : 'none' }}
                            >
                                {e.children.map((d, index) => (
                                    <NavLink
                                        to={d.route}
                                        key={index}
                                        className="nav-link sidebar-sub-menu-item"
                                        activeClassName="active"
                                    >
                                        {d.name}
                                    </NavLink>
                                ))}
                            </Nav>
                        </Collapse>
                    </div>
                ) : e.route === "/startBooking" ? (
                    <NavLink
                        key={i}
                        to={e.route}
                        onClick={() => toggleMenu(i)}
                        className="nav-link"
                        activeClassName="active"
                    >
                        <img
                            src={`../../assets/img/Web/Spacia/${e.icon}`}
                            alt={e.route}
                            style={{
                                height: '20px', width: '20px'
                            }}
                        />{" "}
                        &nbsp;{e.name}
                    </NavLink>
                ) : (
                    <NavLink
                        key={i}
                        to={e.route}
                        onClick={() => toggleMenu(i)}
                        className="nav-link"
                        activeClassName="active"
                    >
                        <img
                            src={`../../assets/img/Web/Spacia/${e.icon}`}
                            alt={e.route}
                            style={{ height: '20px', width: '20px' }}
                        />{" "}
                        &nbsp; {e.name}
                    </NavLink>
                );
            })
            : "";
    };

    return (
        <div className="sideNav min-vh-100 position-fixed" style={{ marginLeft: "12px" }}>
            <figure
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "0px",

                }}
            >
                <Image
                    src={logo}
                    fluid
                    className="mt-5 mb-3"
                    alt="LOGO"
                    width="102"
                    style={{ cursor: "pointer" }}
                    onClick={() => gotToHome()}
                />
            </figure>

            <div
                className="position-relative"
                style={{ height: "calc(100vh - 300px)", overflowY: "auto", paddingRight: "10px", paddingLeft: "0px" }}
            >
                <div className="navItems">
                    <Nav
                        defaultActiveKey={location.pathname}
                        className="flex-column"
                    >
                        {renderMenus()}
                    </Nav>
                </div>
            </div>

            <div style={{
                marginBottom: '0px',
                textAlign: 'center',
                color: '#B4D6FFFF',
                fontSize: '10px',
                transform: 'translateY(80px)'
            }}>
                Powered by theSoftTribe
            </div>
        </div>
    );
};
