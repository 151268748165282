import { toAbsoluteUrl } from "../../../../../../layout/helpers";
import React, { FormEvent, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Constants } from "../../../../../../utils/constants";
import { useDispatch } from "react-redux";
import { persistUserForBundles } from "../../../../../../redux/actions/auth";
import { Spinner } from "react-bootstrap";
import queryString from "query-string";
import { SnackbarProvider, useSnackbar, OptionsObject, closeSnackbar } from 'notistack';
import apiService from "../../../../../../services/apiService";
import { FaRegWindowClose } from "react-icons/fa";

interface ApiError {
    response?: {
        data?: {
            errors?: Array<{ message: string }>;
            status?: number;
            error?: string;
            message?: string;
        };
    };
}

const OTPConfirmation = () => {
    const [otpCode, setOtpCode] = useState("");
    const [username, setUsername] = useState("");
    const [requestCompleted, setRequestCompleted] = useState(true);
    const [errorModal, setErrorModal] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const search = queryString.parse(location.search);
        const params = search ? search.payload : "";
        const payload = params ? atob(params as string) : "";
        const user = payload ? JSON.parse(payload) : null;
        const username = user ? user.username : "";
        setUsername(username);
    }, []);

    const callErrorToast = (message: string) => {
        const options: OptionsObject = {
            variant: 'default',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
            autoHideDuration: 3000,
            action: (key) => (
                <div style={{ display: 'flex', alignItems: 'center', paddingTop: "20px", paddingRight: "20px", paddingBottom: "20px", fontSize: "16px", minWidth: "300px", justifyContent: "space-between" }}>
                    <span style={{ marginRight: '10px', color: 'red' }}>{message}</span>
                    <FaRegWindowClose
                        onClick={() => closeSnackbar(key)}
                        style={{
                            width: '20px',
                            height: '20px',
                            fontSize: '20px',
                            color: 'red',
                            cursor: 'pointer',
                        }}
                    />
                </div>
            ),
            style: {
                backgroundColor: '#FFDDDD', // Light red background
            },
        };
        enqueueSnackbar('', options);
    };

    const verifyAndCompleteRegistration = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setRequestCompleted(false);

        try {
            const response = await apiService.verifyOTP({ otp: otpCode, username });
            const user = response.data?.data?.[0] || {};

            // persist user in session for use in package selection page
            dispatch(persistUserForBundles(user));
            setRequestCompleted(true);
            history.push("/select-package");
        } catch (err) {
            const error = err as ApiError;
            const status = error.response?.data?.status;
            const errorMessage = error.response?.data?.message || "An unexpected error occurred.";

            // Customize the error message based on the status code
            let message = "";
            if (status === 404) {
                message = "The verification code you entered is incorrect. Please try again.";
            } else if (status === 400) {
                message = "Bad request. Please check your input.";
            } else if (status === 401) {
                message = "Unauthorized access. Please log in again.";
            } else if (status === 500) {
                message = "Internal server error. Please try again later.";
            } else {
                message = errorMessage; // Fallback to the default message
            }

            setRequestCompleted(true);
            setErrorModal(true);
            setErrorMsg(message);
            callErrorToast(message);
        }
    };

    return (
        <section className="otp-confirmation">
            <SnackbarProvider>
                <div>
                    <figure style={{ width: "10%", marginBottom: "50px" }}>
                        <img
                            alt={"logo"}
                            decoding={"async"}
                            className={"w100 img-fluid"}
                            src={toAbsoluteUrl("/img/logo-black-alt.png")}
                        />
                    </figure>
                    <section className={"otp-form"}>
                        <div>
                            <p
                                style={{
                                    color: "#333333",
                                    fontSize: "1.7rem",
                                    paddingTop: "25px",
                                    marginBottom: "20px",
                                }}
                            >
                                Verification code
                            </p>
                            <form onSubmit={verifyAndCompleteRegistration}>
                                <label style={{ marginBottom: "20px" }}>
                                    Enter your email verification code <br />
                                    <input
                                        type="text"
                                        className="form-control form-control-md border border-dark"
                                        autoComplete="off"
                                        value={otpCode}
                                        onChange={(e) => setOtpCode(e.target.value)}
                                    />
                                </label>
                                <div className="sign-in-cta">
                                    <button
                                        type="submit"
                                        className="btn btn-primary w-100"
                                        disabled={!otpCode || otpCode.length < 4}
                                    >
                                        Sign in
                                        <Spinner
                                            size="sm"
                                            animation="border"
                                            role="status"
                                            style={{
                                                display: !requestCompleted ? "inline-block" : "none",
                                                marginLeft: "10px",
                                                width: "15px",
                                                height: "15px",
                                            }}
                                        >
                                            <span className="visually-hidden"></span>
                                        </Spinner>{" "}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </section>
                </div>
            </SnackbarProvider>
        </section>
    );
};

export default OTPConfirmation;

