import React, { useState, useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import bg from '../../assets/img/spacia/web/Background.png';
import { toAbsoluteUrl } from '../../layout/helpers';
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import apiService from '../../services/apiService';

const isBase64 = (str) => {
    if (!str) return false;
    try {
        return btoa(atob(str)) === str;
    } catch (err) {
        return false;
    }
};

const SavePasswordMobile = () => {
    const [token, setToken] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);

    const location = useLocation();
    const search = queryString.parse(location.search);
    const params = search ? search.payload : "";

    const logopos = {
        position: 'relative',
        left: '5vw',
        top: '5vh'
    };

    let payload = null;
    if (isBase64(params)) {
        try {
            payload = JSON.parse(atob(params));
        } catch (e) {
            console.error('Error parsing Base64 payload:', e);
            setError('Error processing payload.');
        }
    } else if (params) {
        console.error('Invalid Base64 string:', params);
        setError('Invalid Base64 data.');
    }

    useEffect(() => {
        if (payload && payload.otp) {
            setToken(payload.otp);
        }
    }, [payload]);

    const bgImageMobile = {
        width: '100vw',
        height: '100vh',
        background: 'white',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        position: 'relative',
        backgroundImage: `url(${bg})`
    };

    const formCardMobile = {
        width: '90%',
        position: 'absolute',
        left: '5%',
        top: '20vh',
        backgroundColor: 'white',
        padding: 20,
        boxShadow: "0px 4px 38px rgba(0, 0, 0, 0.06)",
        borderRadius: 8
    };

    const logoposMobile = {
        position: 'relative',
        left: '50%',
        transform: 'translateX(-50%)',
        top: '5vh',
        textAlign: 'center'
    };

    const googleButton = {
        display: "flex",
        width: '100%',
        border: '1px solid #D9D9D9',
        padding: 10,
        borderRadius: 10,
        backgroundColor: 'white'
    };

    const history = useHistory();

    const activateAccount = async () => {
        if (!password || !confirmPassword) {
            console.error('Password fields cannot be empty');
            setError('Password fields cannot be empty');
            return;
        }

        if (password !== confirmPassword) {
            console.error('Passwords do not match');
            setError('Passwords do not match');
            return;
        }

        try {
            const res = await apiService.savePassword({
                confirmPassword,
                password,
                token
            });

            console.log(res.data);
            history.push("/signin");
        } catch (err) {
            console.error('Error during API call:', err.response ? err.response.data : err.message);
            setError('Failed to change password. Please try again.');
        }
    };

    if (error) {
        return <div>Error: {error}</div>;
    }


    return (
        <Row style={bgImageMobile}>
            <div style={logopos}>
                <img
                    alt="Logo"
                    src={toAbsoluteUrl('/assets/img/spacia/web/spacia_logo.png')}
                    style={{ width: '120px', height: 'auto' }}
                />
            </div>
            <div style={formCardMobile}>
                <Container>
                    <h5>Change password</h5>
                    <br />
                    <h6>Please fill in your new password to continue to the signin page.</h6>
                    <br />
                    <div className="form-group">
                        <label htmlFor="">New Password</label>
                        <input type="password" className="form-control" placeholder="" onChange={e => setPassword(e.target.value)} value={password} />
                    </div>

                    <div className="form-group">
                        <label htmlFor="">Confirm New Password</label>
                        <input type="password" className="form-control" placeholder="" onChange={e => setConfirmPassword(e.target.value)} value={confirmPassword} />
                    </div>
                    <br />
                    <div style={{ margin: 'auto', textAlign: 'center' }}>
                        <button onClick={activateAccount} className="button" style={{ width: '100%' }}>Change Password</button>
                    </div>
                </Container>
            </div>
        </Row>
    );
};

export default SavePasswordMobile;
