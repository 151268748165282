// @ts-nocheck

/**
 * Project: spaces
 * File: Login
 * Created by Pennycodes on 2/4/2022.
 * Copyright spaces
 */
import React, { FormEvent, useLayoutEffect, useState } from "react";
import { CloseCircle, EyeOutline, EyeOffOutline } from "react-ionicons";
import { Link, useLocation, useHistory } from "react-router-dom";
import { PageTitle, RightComponent, LeftComponent } from "../../layout/PageData";
import { toAbsoluteUrl } from "../../layout/helpers";
import queryString from "query-string";
import apiService from "../../services/apiService";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { getMessaging, getToken } from "firebase/messaging";
import { getApp } from "firebase/app";
import { toast } from 'react-toastify';
import SERVICES from '../../services';

const inputStyles = {
    WebkitBoxShadow: "0 0 0 30px white inset !important",
    WebkitTextFillColor: "#000000 !important",
    transition: "background-color 5000s ease-in-out 0s",
    background: "white !important"
};

const StyledEmailInput = ({ email, setEmail }) => {
    return (
        <div className="form-group basic">
            <div className="input-wrapper">
                <input
                    type="email"
                    className="form-control input-new-rounded floating-input"
                    id="email1"
                    autoComplete="username"
                    placeholder=" "
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    style={{
                        backgroundColor: 'white',
                        WebkitBoxShadow: '0 0 0 1000px white inset',
                        WebkitTextFillColor: '#000'
                    }}
                />
                <label
                    className="floating-label-new-background"
                    htmlFor="email1"
                >
                    E-mail
                </label>
                {email && (
                    <i className="clear-input">
                        <CloseCircle
                            color={"#00000"}
                            title={"Clear"}
                            onClick={() => setEmail('')}
                            style={{ cursor: 'pointer' }}
                        />
                    </i>
                )}
            </div>
        </div>
    );
};

const StyledPasswordInput = ({ password, setPassword }) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <div className="form-group basic">
            <div className="input-wrapper">
                <input
                    type={showPassword ? "text" : "password"}
                    className="form-control floating-input input-new-rounded"
                    id="password1"
                    autoComplete="current-password"
                    placeholder=" "
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    style={{
                        backgroundColor: 'white',
                        WebkitBoxShadow: '0 0 0 1000px white inset',
                        WebkitTextFillColor: '#000'
                    }}
                />
                <label
                    className="floating-label-new-background"
                    htmlFor="password1"
                >
                    Password
                </label>
                <i
                    className="clear-input"
                    onClick={() => setShowPassword(!showPassword)}
                    style={{ cursor: 'pointer' }}
                >
                    {showPassword ? (
                        <EyeOffOutline
                            color={"#00000"}
                            title={"Hide password"}
                            height="24px"
                            width="24px"
                        />
                    ) : (
                        <EyeOutline
                            color={"#00000"}
                            title={"Show password"}
                            height="24px"
                            width="24px"
                        />
                    )}
                </i>
            </div>
        </div>
    );
};

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const history = useHistory();
    const location = useLocation();
    const search = queryString.parse(location.search);
    const params = search && search.payload ? search.payload : "";

    const handleLogin = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let credentials = {
            password,
            username: email,
            companyId: 1,
        };

        try {
            const res = await apiService.login(credentials);
            
            if (res.status === 200) {
                const user = res.data.data[0];
                
                if (!user) {
                    console.error("No user data received");
                    toast.error("Login failed: No user data received");
                    return;
                }

                await onLoginSuccessful(res);

                const fcmToken = await getFCMToken();
                
                if (fcmToken) {
                    try {
                        await apiService.updateFCMToken(user.id, fcmToken);
                    } catch (updateError) {
                        console.error("Error updating FCM token:", updateError);
                        toast.warn("Failed to update notification settings", {
                            position: "top-right",
                            theme: "colored"
                        });
                    }
                }
            }
        } catch (err) {
            console.error("Login error:", err);
            toast.error('Login failed. Please try again.', {
                position: "top-right",
                theme: "colored"
            });
        }
    };

    useLayoutEffect(() => {
        const sidebarPanel = document.getElementById('sidebarPanel') as HTMLAnchorElement;
        sidebarPanel.addEventListener('shown.bs.modal', function (event) {
            sidebarPanel.setAttribute('data-bs-dismiss', 'modal');
        });
    }, []);

    const googleLogin = async () => {
        try {
            const auth = getAuth();
            const provider = new GoogleAuthProvider();
            provider.setCustomParameters({
                prompt: "select_account"
            });

            const result = await signInWithPopup(auth, provider);
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const accessToken = credential?.accessToken;

            if (!accessToken) {
                throw new Error("No access token received from Google");
            }

            const response = await apiService.googleAuth({
                accessToken,
                companyId: 1,
                provider: "GOOGLE"
            });

            await onLoginSuccessful(response);

            const user = response.data.data[0];
            const fcmToken = await getFCMToken();

            if (fcmToken) {
                try {
                    await apiService.updateFCMToken(user.id, fcmToken);
                } catch (updateError) {
                    console.error("Error updating FCM token:", updateError);
                    toast.warn("Failed to update notification settings", {
                        position: "top-right",
                        theme: "colored"
                    });
                }
            }
        } catch (error) {
            console.error("Google login error:", error);
            toast.error('Failed to login with Google. Please try again.', {
                position: "top-right",
                theme: "colored"
            });
        }
    };

    const getFCMToken = async () => {
        try {
            const app = getApp();
            const messaging = getMessaging(app);
            const token = await getToken(messaging);
            console.log("FCM Token:", token);
            return token;
        } catch (error) {
            console.error("Error getting FCM token:", error);
            return null;
        }
    };

    function onLoginSuccessful(res: AxiosResponse<any>) {
        const response = res.data;
        const responseData = response?.data;
        const currentUser = (responseData && responseData.length > 0) ? responseData[0] : null;
        const packagePlan = currentUser?.packagePlan;

        // Save package plan status in localStorage
        if (packagePlan) {
            localStorage.setItem('hasPackagePlan', 'true');
            console.log("User has package plan:", packagePlan);
        } else {
            localStorage.setItem('hasPackagePlan', 'false');
            console.log("User does not have package plan");
        }

        SERVICES.saveUser(currentUser);

        const userCountry = currentUser?.country;
        const currency = userCountry.currency;

        console.log('currency associated with company is:', currency);

        SERVICES.setCurrency(currency);

        const role = currentUser?.role;
        console.log('current user role is:', role);

        const companyId = currentUser ? currentUser.companyId : 0;
        SERVICES.setCompanyId(companyId);

        if (role != null) {
            switch (role) {
                case 'ROLE_SUBSCRIBER_INDIVIDUAL':
                case 'ROLE_SUBSCRIBER_APPROVER':
                case 'ROLE_SUBSCRIBER_OWNER':
                    history.push('/searchPage');
                    break;

                case 'ROLE_HOST':
                case 'ROLE_HOST_OPERATIONS':
                case 'ROLE_ADMINISTRATOR':
                    history.push('/properties');
                    break;

                default:
                    history.push('/home');
            }
        }
    }

    const isValidJSON = (str: string) => {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    };

    let payload = null;
    // @ts-ignore
    const decodedString = atob(params);
    if (isValidJSON(decodedString)) {
        payload = JSON.parse(decodedString);
        // localStorage.setItem('payload',payload)
        SERVICES.saveUser(payload);
    }

    const onLoginError = (error: any) => {
        console.error('Google login failed:', error);
        setError('Failed to login with Google. Please try again.');
    };

    return (
        <div className="position-relative overflow-hidden">
            <PageTitle>{false}</PageTitle>
            <RightComponent>{false}</RightComponent>
            <LeftComponent>{false}</LeftComponent>

            <div className="section position-relative p-0">
                <img
                    className="w-100"
                    decoding={"async"}
                    src={toAbsoluteUrl("/img/Rectangle 18.png")}
                    alt={"Logo"}
                />

                <div className="section mt-2 position-absolute"
                    style={{ bottom: "88px", left: "16px" }}>
                    <h2 className={"text-capitalize text-white"}>Sign in</h2>
                    <h4 className={"mt-2 text-white"}>Welcome back to SPACIA!</h4>
                </div>
            </div>
            <div className="section mb-5 p-2 position-relative"
                style={{
                    backgroundColor: "white",
                    borderTopRightRadius: "30px",
                    borderTopLeftRadius: "30px",
                    bottom: "21px",
                    transform: "scaleX(1.02)",
                    transformOrigin: "center",
                }}>
                <form
                    onSubmit={handleLogin}
                >
                    <div className="card-body pb-1">
                        <StyledEmailInput email={email} setEmail={setEmail} />
                        <StyledPasswordInput password={password} setPassword={setPassword} />
                        <div className="mt-2 mb-4">
                            <Link to="/reset-password-mobile" style={{ color: '#1F2937', textDecoration: 'none' }}>
                                Forgot Password?
                            </Link>
                        </div>
                        <div className="form-button-group">
                            <div className="form-links mt-2 flex-fill justify-content-end">
                                {/* <div className="form-check mb-1">
								<input
									type="checkbox"
									className="form-check-input"
									id="customCheckb1"
									checked={rememberMe}
									onChange={() => setRememberMe(!rememberMe)}
								/>
								<label
									className="form-check-label fw-bold"
									htmlFor="customCheckb1"
								>
									Remember Me
								</label>
							</div> */}

                                <a href="#1" className="ms-lg-auto" >Forgot Password?</a>

                            </div>

                            <div className="mt-4">
                                <button type='submit' className={"btn btn-primary btn-block btn-lg"} style={{ borderRadius: "40rem" }}>
                                    Sign in
                                </button>
                            </div>
                            <div className="mt-2 text-center">
                                <p className={"text-dark"}>
                                    Don't have an account? <Link to={"/signup"}>Sign Up</Link>{" "}
                                </p>
                            </div>
                            <div className="text-center mt-2">
                                <p className="text-muted text-lowercase">or continue with</p>
                            </div>
                            <div className="d-flex flex-row justify-content-center align-items-center">
                                {/*<button*/}
                                {/*	type="submit"*/}
                                {/*	className="btn"*/}
                                {/*>*/}
                                {/*	<img*/}
                                {/*		src={toAbsoluteUrl("/img/Facebook.png")}*/}
                                {/*		alt={"Facebook Sign In"}*/}
                                {/*	/>*/}
                                {/*</button>*/}
                                {/*<button*/}
                                {/*	type="submit"*/}
                                {/*	className="btn"*/}
                                {/*>*/}
                                {/*	<img*/}
                                {/*		src={toAbsoluteUrl("/img/apple.png")}*/}
                                {/*		alt={"Apple Sign In"}*/}
                                {/*	/>*/}
                                {/*</button>*/}
                                <button
                                    type="button"
                                    className="btn"
                                    onClick={googleLogin}
                                >
                                    <img
                                        src={toAbsoluteUrl("/img/google.png")}
                                        alt={"Google Sign In"}
                                    />
                                </button>

                            </div>

                            <div className="mt-4 text-center">
                                <p className={"text-muted text-capitalize text-center mb-auto"}>
                                    &copy;2022 SPACIA All rights reserved
                                </p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            {error && (
                <div className="alert alert-danger mt-3">
                    {error}
                </div>
            )}
        </div>
    );
};
export default Login;
