import React, { useState, useEffect } from "react";
// import TextField from '@material-ui/core/TextField';
// import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
// import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
// import DatePicker from "@material-ui/lab/DatePicker";
import {
	Container,
	Table,
	Toast,
	Button,
	DropdownButton,
	Dropdown,
	InputGroup,
	FormControl, Spinner,
} from "react-bootstrap";
import { FaCaretDown, FaEllipsisH, FaSearch } from "react-icons/fa";
import PaymentTableRow from "../../../../components/PaymentTableRow";
import Search from "../../../../components/Search";
import UserTableRow from "../../../../components/UserTableRow";
import SERVICES from '../../../../services';
import apiService from '../../../../services/apiService';
import moment from 'moment-timezone';
import { Constants } from '../../../../utils/constants';
import ReactPaginate from "react-paginate";
import { BillingStyles } from "../../BillingStyles";
import Pagination from "../../../../components/Pagination";
import { useHistory } from 'react-router-dom';
import { InvoiceModal as Modal, MobileWalletModal } from "../../../../components/Modals/ModalStyles";
import warning from "../../../../img/Web/Spacia/t-warning 3.svg";
import { BlockerModal } from "../../../../components/FeedbackModall";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import AdminOrdersTableRow from "../../../../components/AdminOrdersTableRow";
import { ErrorAlert } from "../../../../components/Settings/alerts/ErrorAlert";
import SuccessAlert from "../../../../components/Settings/alerts/SuccessAlert";

const Orders = () => {
	const [show, setShow] = useState(false);
	const [showSuccessAlert, setShowSuccessAlert] = useState(false);
	const [showErrorAlert, setShowErrorAlert] = useState(false);
	const [showStatementModal, setShowStatementModal] = useState(false);
	const [hasNoMobileWalletAccount, setHasNoMobileWalletAccount] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const [totalPageSize, setTotalPageSize] = useState(0);
	const [itemsPerPage, setItemsPerPage] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [currentUserRole, setCurrentUserRole] = useState('');
	const [allUnpaidInvoices, setAllUnpaidInvoices] = useState([]);
	const [makeRequestForUnpaidInvoices, setMakeRequestForUnpaidInvoices] = useState(false);
	const [totalAmount, setTotalAmount] = useState(0);
	const [selectedInvoices, setSelectedInvoices] = useState(null);
	const [mobileWalletAccounts, setMobileWalletAccounts] = useState([]);
	const [currency, setCurrency] = useState('');

	const [selectedMonthAndYear, setSelectedMonthAndYear] = useState(new Date());
	const [monthStartDate, setMonthStartDate] = useState("");
	const [monthEndDate, setMonthEndDate] = useState("");
	const [searchValue, setSearchValue] = useState('');

	const history = useHistory();

	useEffect(() => {
		setCurrency(SERVICES.getCurrency());
	}, []);

	const getBookingsSuccessful = (res) => {
		setallpayments(res.data.data);
	};

	const [allpayments, setallpayments] = useState([]);
	const [token, setToken] = useState('');
	const [userId, setuserId] = useState('N/A');

	useEffect(() => {
		const currentUser = SERVICES.getUser();
		const role = currentUser?.role;
		setCurrentUserRole(role);
		setuserId(currentUser);
		setToken('123');

		apiService.getOrders(currentUser.id, 0, searchValue)
			.then((res) => {
				if (res.status === 200) {
					setTotalPageSize(res.data.totalCount);
					setItemsPerPage(res.data.pageSize);
					getBookingsSuccessful(res);
				}
			})
			.catch(console.log);
	}, []);

	useEffect(() => {
		const currentUser = SERVICES.getUser();
		const userId = currentUser ? currentUser.id : 0;

		apiService.getMobileWalletAccounts(userId)
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data.data;
					setMobileWalletAccounts(responseData);
				}
			});
	}, []);

	const formatDateToString = (date) => {
		let dateStr = null;

		if (date) {
			console.log("about to format date: " + date + " to 'dd/MM/yyyy'");

			try {
				dateStr = moment(date).format("DD/MM/yyyy");
			} catch (e) {
				console.log("an error occurred while formatting date to 'dd/MM/yyyy' string");
			}
		}
		return dateStr;
	};

	const handleMonthAndYearChange = (value) => {
		console.log('month / year value is:', value);

		const date = new Date(value);
		const year = date.getFullYear(); // get date full year
		const month = date.getMonth(); // get month

		const startOfMonth = formatDateToString(new Date(year, month, 1));
		const endOfMoth = formatDateToString(new Date(year, (month + 1), 0));

		console.log('start of month:', startOfMonth);
		console.log('end of month:', endOfMoth);

		setMonthStartDate(startOfMonth);
		setMonthEndDate(endOfMoth);

		setSelectedMonthAndYear(value);
	};

	const downloadStatement = () => {
		let startAt = monthStartDate;
		let endAt = monthEndDate;

		const year = selectedMonthAndYear.getFullYear(); // get date full year
		const month = selectedMonthAndYear.getMonth(); // get month

		const startOfMonth = formatDateToString(new Date(year, month, 1));
		const endOfMoth = formatDateToString(new Date(year, (month + 1), 0));

		if (!startAt) {
			startAt = startOfMonth;
		}

		if (!endAt) {
			endAt = endOfMoth;
		}

		console.log('downloading statement. start date:', startAt);
		console.log('downloading statement. end date:', endAt);

		const currentUser = SERVICES.getUser();
		const userId = currentUser ? currentUser.id : 0;

		apiService.downloadStatement(userId, startAt, endAt)
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'statement.pdf');
				document.body.appendChild(link);
				link.click();
			});
	};

	const viewPayment = (token) => {
		console.log(token);
		//axios.get(`${Constants.BASE_URL}/users/invoice/${userId.id}/${token}.pdf`)
		const currentUser = SERVICES.getUser();
		apiService.downloadInvoice(currentUser.id, token)
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `invoice-${token}.pdf`);
				document.body.appendChild(link);
				link.click();
			});
	};

	const pageCount = Math.ceil(totalPageSize / itemsPerPage);

	const changePage = (selected) => {
		const currentPage = selected.selected + 1;
		const currentUser = SERVICES.getUser();
		setuserId(currentUser);
		setToken('123');
		setCurrentPage(currentPage);

		apiService.getOrders(currentUser.id, currentPage - 1, searchValue)
			.then((res) => {
				if (res.status === 200) {
					setTotalPageSize(res.data.totalCount);
					setItemsPerPage(res.data.pageSize);
					getBookingsSuccessful(res);
				}
			})
			.catch(console.log);
	};

	function performSearch(e) {
		e.preventDefault();

		setCurrentPage(1);

		const currentUser = SERVICES.getUser();
		const currentUserId = currentUser ? currentUser.id : 0;

		apiService.getOrders(currentUserId, 0, searchValue)
			.then((res) => {
				if (res.status === 200) {
					setTotalPageSize(res.data.totalCount);
					setItemsPerPage(res.data.pageSize);
					getBookingsSuccessful(res);
				}
			})
			.catch(console.log);
	}

	const showMakePaymentPage = () => {
		history.push({
			pathname: '/make-payment',
			state: {
				invoiceAmount: totalAmount,
				selectedInvoices
			}
		});
	};

	const displayMobileWalletAccountSetupPage = () => {
		history.push('/settings/momopayment');
	};

	useEffect(() => {
		const currentUser = SERVICES.getUser();
		const userId = currentUser?.id;

		if (show) {
			setMakeRequestForUnpaidInvoices(true);
			
			apiService.getUnpaidInvoices(userId, searchValue)
				.then((res) => {
					if (res.status === 200) {
						setMakeRequestForUnpaidInvoices(false);
						const invoices = res.data.data;
						const allUnPaidInvoices = invoices.filter(invoice => 
							invoice.isActive && !invoice.isCancelled && !invoice.isPaid
						);
						setAllUnpaidInvoices(allUnPaidInvoices);
					}
				})
				.catch((error) => {
					setMakeRequestForUnpaidInvoices(false);
					setAllUnpaidInvoices([]);
					console.log(error);
				});
		}
	}, [show]);

	const showInvoiceModal = () => {
		setShow(true);
	};

	const renderResultForUnpaidInvoices = () => {
		let whatToRender = '';

		if (makeRequestForUnpaidInvoices) {
			whatToRender = (
				<div style={{ display: 'flex', justifyContent: 'center', padding: '70px 0' }}>
					<Spinner size="sm" animation="border" role="status" style={{ width: '50px', height: '50px' }}>
						<span className="visually-hidden"></span>
					</Spinner>{" "}
				</div>
			);
		} else if (!makeRequestForUnpaidInvoices && allUnpaidInvoices.length === 0) {
			whatToRender = (
				<div style={{ display: 'flex', justifyContent: 'center', padding: '70px 0' }}>
					<p style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>No Unpaid Invoices</p>
				</div>
			);
		} else if (!makeRequestForUnpaidInvoices && allUnpaidInvoices.length > 0) {
			whatToRender = (
				<div role='table'>
					<table>
						<thead>
							<tr>
								<th scope='col'>Number</th>
								<th scope='col'>Value</th>
								<th scope='col'>Date</th>
								<th scope='col'>Check All &nbsp; <input type="checkbox" onChange={e => {
									const isChecked = e.currentTarget.checked;

									console.log('is checked?', isChecked);

									// select all checkboxes
									const allInvoiceCheckboxes = document.querySelectorAll('.invoice-check');
									allInvoiceCheckboxes.forEach(checkbox => {
										checkbox.checked = isChecked;
									});

									if (isChecked) {
										const selectedInvoicesAmount = allUnpaidInvoices.map(o => o.netAmount).reduce((val1, val2) => val1 + val2, 0);
										console.log('selected invoices amount:', selectedInvoicesAmount);
										setTotalAmount(selectedInvoicesAmount);

										setSelectedInvoices(allUnpaidInvoices);
									} else {
										setTotalAmount(0);
										setSelectedInvoices(null);
									}

								}} /></th>
							</tr>
						</thead>
						<tbody>
							{
								allUnpaidInvoices.map((unpaidInvoice, index) => {
									const amount = unpaidInvoice.netAmount.toLocaleString();

									const invoiceValue = `${currency} ${amount}`;
									return <tr>
										<td>{index + 1}</td>
										<td>{invoiceValue}</td>
										<td>{moment(unpaidInvoice.createdOn).format('DD-MMM-YYYY')}</td>
										<td>
											<input type="checkbox" className='invoice-check' onChange={(e) => {
												// console.log('pending hosts find:', allUnpaidInvoices.find(o=>o.referenceCode === unpaidInvoice.referenceCode).checked = e.target.checked);
												// pendingHosts.find(o=>o.companyId===pendingHost.companyId).checked = e.target.checked;
												// const selectedInvoicesAmount = allUnpaidInvoices.filter(o=>o.checked===true).map(o=> o);
												const checked = allUnpaidInvoices.find(o => o.referenceCode === unpaidInvoice.referenceCode).checked = e.target.checked;
												const selectedInvoicesAmount = allUnpaidInvoices.filter(o => o.checked === true).map(o => o.netAmount).reduce((val1, val2) => val1 + val2, 0);
												console.log('selected invoices amount:', selectedInvoicesAmount);
												setTotalAmount(selectedInvoicesAmount);

												const checkedInvoices = allUnpaidInvoices.find(o => o.referenceCode === unpaidInvoice.referenceCode).checked = e.target.checked;
												const selectedInvoices = allUnpaidInvoices.filter(o => o.checked === true).map(o => o);

												setSelectedInvoices(selectedInvoices);

											}} />
										</td>
									</tr>;
								})
							}
						</tbody>
					</table>
				</div>
			);
		}

		return whatToRender;
	};

	return (
		<div>
			<SuccessAlert show={showSuccessAlert} message="Booking confirmation email has been sent to the account's email" close={() => setShowSuccessAlert(false)} />
			<ErrorAlert show={showErrorAlert} message={"An error occurred while completing action"} close={() => setShowErrorAlert(false)} />
			<div>
				{/* Header */}
				<div className="header">
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: 'center'
						}}
					>
						<div>
							<h4 style={{ fontSize: "1.5rem" }}>
								<b>Orders</b>
							</h4>
						</div>
						<form className="form-inline" style={{ width: '30%' }} onSubmit={performSearch}>
							<input style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, marginRight: '0!important' }} className="form-control mr-sm-2 search" placeholder='Search' type="search" aria-label="Search" value={searchValue} onChange={e => setSearchValue(e.target.value)} />
							<button style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} className="btn btn-outline-dark my-2 my-sm-0" type="submit"><FaSearch /></button>
						</form>
						{/**/}

						{/*		<div className="dropdown" style={{ margin: "auto 30px", width: '40%', display: 'flex' }}>*/}
						{/*			<button type='button' onClick={() => setShowStatementModal(true)} className="button" style={{ width: "100%", marginRight: '20px' }}>*/}
						{/*				Download Statement*/}
						{/*			</button>*/}
						{/*			<button type='button' onClick={showInvoiceModal} className="button" style={{ width: "100%" }}>*/}
						{/*				Make Payment*/}
						{/*			</button>*/}
						{/*			/!* <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">*/}
						{/*            Dropdown button*/}
						{/*        </button>*/}
						{/*        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">*/}
						{/*            <a className="dropdown-item" href="#">Action</a>*/}
						{/*            <a className="dropdown-item" href="#">Another action</a>*/}
						{/*            <a className="dropdown-item" href="#">Something else here</a>*/}
						{/*        </div> *!/*/}
						{/*			/!* <Dropdown>*/}
						{/*				<Dropdown.Toggle*/}
						{/*					style={{*/}
						{/*						backgroundColor: "#ECECEC",*/}
						{/*						border: "none",*/}
						{/*						color: "#848484",*/}
						{/*					}}*/}
						{/*					variant="success"*/}
						{/*					id="dropdown-basic"*/}
						{/*				>*/}
						{/*					Filter*/}
						{/*				</Dropdown.Toggle>*/}

						{/*				<Dropdown.Menu>*/}
						{/*					<Dropdown.Item href="#/action-1">*/}
						{/*						Action*/}
						{/*					</Dropdown.Item>*/}
						{/*					<Dropdown.Item href="#/action-2">*/}
						{/*						Another action*/}
						{/*					</Dropdown.Item>*/}
						{/*					<Dropdown.Item href="#/action-3">*/}
						{/*						Something else*/}
						{/*					</Dropdown.Item>*/}
						{/*				</Dropdown.Menu>*/}
						{/*			</Dropdown> *!/*/}
						{/*		</div>*/}
					</div>
				</div>
				{/* End Header */}

				<Container>
					<BillingStyles>
						<Toast
							style={{ width: "100%", padding: 20, marginBottom: 50, maxWidth: '100%' }}
						>
							<h6 style={{ fontWeight: "bold" }}>
								Transaction History
							</h6>
							<Table style={{ tableLayout: 'fixed' }}>
								<tr className="tableUnderline">
									<th>Date</th>
									<th>Customer</th>
									<th>Amount</th>
									<th>Invoice</th>
									<th>Status</th>
								</tr>

								{
									allpayments.map((payment) => {
										let paymentStatus = undefined;
										let paymentAccount = undefined;

										const order = payment?.order;
										const orderStatus = order?.orderStatus;

										console.log('order status is:', orderStatus);

										if (payment.isPaid) {
											paymentStatus = 'PAID';
											paymentAccount = 'Customer Account';
										} if (payment.isActive) {
											paymentStatus = 'ACTIVE';
											paymentAccount = 'Payment pending';
										} if (payment.isCancelled) {
											paymentStatus = 'CANCELLED';
											paymentAccount = 'Not applicable';
										} if (orderStatus && orderStatus === 'incomplete') {
											paymentStatus = 'INCOMPLETE';
										}

										console.log('payment status here is:', paymentStatus);

										const createdBy = payment?.createdBy;
										const firstName = createdBy?.firstName;
										const lastName = createdBy?.lastName;

										let customer = '';

										if (firstName && lastName) {
											customer = `${firstName} ${lastName}`;
										} else if (firstName) {
											customer = firstName;
										} else if (lastName) {
											customer = lastName;
										}

										const company = createdBy?.company;
										const companyName = company?.name;

										const role = createdBy?.role;

										return <AdminOrdersTableRow
											date={moment(payment.createdOn).format('DD-MMM-YYYY HH:mm')}
											// bank={mastercard}
											customer={customer}
											company={companyName}
											status={paymentStatus}
											createdById={payment.createdById}
											order={payment.order}
											role={role}
											invoice={payment}
											mobileWalletAccounts={mobileWalletAccounts}
											setHasNoWalletWalletAccount={setHasNoMobileWalletAccount}
											amount={payment.grossAmount}
											currency={SERVICES.getCurrency()}
											setShowSuccessAlert={setShowSuccessAlert}
											setShowErrorAlert={setShowErrorAlert}
											onView={() => viewPayment(payment.referenceCode)}
										/>;
									})}
								{
									Array.isArray(allpayments) && allpayments.length > 0 &&
									<caption>
										<Pagination
											className="pagination-bar"
											currentPage={currentPage || 1}
											totalCount={totalPageSize || 0}
											pageSize={itemsPerPage || 10}
											siblingCount={1}
											onPageChange={changePage}
										/>
									</caption>
								}
							</Table>
							{/* <div style={{ width: '300px' }}>
								// <ReactPaginate
								// 	previousLabel={'<'}
								// 	nextLabel={'>'}
								// 	pageCount={pageCount}
								// 	marginPagesDisplayed={0}
								// 	pageRangeDisplayed={6}
								// 	onPageChange={changePage}
								// 	containerClassName={'whole-block'}
								// 	activeClassName={'paginate-active'}
								// 	disabledClassName={'paginate-disabled'}
								// 	previousLinkClassName={'previous-link'}
								// 	nextLinkClassName={'next-link'}/>
							</div> */}
						</Toast>
					</BillingStyles>

					{/* <Toast style={{width:'100%', padding:20}}>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                    <Search />
                    <div style={{display:'flex'}}>
                        <h6 className="text-muted" style={{marginRight:10}}>Sort by</h6>
                        <h6 style={{fontWeight:'bold'}}>Status-invited</h6>
                        <FaCaretDown />
                    </div>
                </div>
                <Table className="w3-striped">
                    <tr className="tableUnderline">
                        <th>Name</th>
                        <th>Email</th>
                        <th>Status</th>
                        <th>Role</th>
                        <th>Action</th>
                    </tr>
                    <tr>
                        <td><div> <img src="" alt="User"/>Nana Kweku Adumatta</div></td>
                        <td>mr.adumatta@gmail.com</td>
                        <td>Active</td>
                        <td><button className="role-admin">Active</button></td>
                        <td>Edit</td>
                        <td><FaEllipsisH/></td>
                    </tr>
                </Table>
                </Toast> */}

					<Modal className='statement-modal' show={showStatementModal} onHide={() => setShowStatementModal(false)}>
						<Modal.Header style={{
							borderBottom: "none",
							// paddingLeft: "30px",
							// paddingRight: "30px",
							margin: "0px",
						}}
							closeButton>
						</Modal.Header>
						<Modal.Body style={{ padding: '30px 0 50px 0', marginRight: 'auto' }}>
							<div className='datetime-picker' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<DatePicker
										views={['month', 'year']}
										minDate={new Date('2020-01-01')}
										maxDate={new Date('2050-12-31')}
										value={selectedMonthAndYear}
										onChange={(newValue) => {
											handleMonthAndYearChange(newValue);
										}}
										renderInput={(params) => <TextField {...params} helperText={null} />}
									/>
								</LocalizationProvider>
								<button type='button' style={{ width: '35%', cursor: 'pointer' }} className='button' onClick={downloadStatement}>Get Statement</button>
							</div>
						</Modal.Body>
					</Modal>

					<Modal show={show} onHide={handleClose} className={"text-primary mt-5"}
						style={{ fontSize: "1.3rem", fontWeight: 200 }}>
						<Modal.Header closeButton>
							<div className=""></div>
						</Modal.Header>
						<Modal.Body>
							<header>
								<div>
									<h2>Select Invoice</h2>
									<h2>Total: {SERVICES.getCurrency()} {totalAmount}</h2>
								</div>
								<button type='button' style={{ width: '30%', cursor: (!selectedInvoices || (selectedInvoices && selectedInvoices.length <= 0)) ? 'not-allowed' : 'pointer' }} disabled={!selectedInvoices || (selectedInvoices && selectedInvoices.length <= 0)} className='button' onClick={showMakePaymentPage}>Continue</button>
							</header>
							{
								renderResultForUnpaidInvoices()
							}
						</Modal.Body>
					</Modal>

					<BlockerModal title="Sorry" isOpen={hasNoMobileWalletAccount} isClose={() => setHasNoMobileWalletAccount(false)} onSubmit={() => history.push("/settings/momopayment")} doneButton="  Account Setup  ">
						<p style={{ fontWeight: 400, fontSize: '1.05rem' }} className="text-muted">Whoa! You do not have a mobile wallet account set up. Please click on the button below to set up a mobile wallet account</p>
						<br />
						<img src={warning} alt="warning" />
					</BlockerModal>
					{/* <MobileWalletModal show={hasNoMobileWalletAccount} onHide={() => setHasNoMobileWalletAccount(false)}>
					{/*	<Modal.Header closeButton>*/}
					{/*	</Modal.Header>*/}
					{/*	<Modal.Body>*/}
					{/*		<section>*/}
					{/*			<h5 style={{marginBottom: '20px'}}>You do not have a mobile wallet account set up.</h5>*/}
					{/*			<p style={{marginBottom: '30px'}}>Please click on the button below to set up a mobile wallet account</p>*/}
					{/*			<div style={{textAlign: 'center'}}>*/}
					{/*				<Button variant="danger" size="md" onClick={displayMobileWalletAccountSetupPage}>Account Setup</Button>*/}
					{/*			</div>*/}
					{/*		</section>*/}
					{/*	</Modal.Body>*/}
					{/*</MobileWalletModal>*/}
				</Container>
			</div>
		</div>
	);
};

export default Orders;
