export const saveUser = (user) => {
	if (!user) {
		console.warn("Attempting to save null user");
		return;
	}
	localStorage.setItem('currentUser', JSON.stringify(user));
	console.log("Saved user to localStorage:", user);

	// Save token to localStorage
	if (user.token) {
		localStorage.setItem('authToken', user.token);
		console.log("Saved auth token to localStorage:", user.token);
	}
};

export const getUser = () => {
	try {
		const user = localStorage.getItem('currentUser');
		if (!user) return null;
		
		const parsedUser = JSON.parse(user);
		console.log("Retrieved user from localStorage:", parsedUser);
		return parsedUser;
	} catch (error) {
		console.error("Error parsing user data from localStorage:", error);
		// Clear invalid data
		localStorage.removeItem('currentUser');
		return null;
	}
};

export const getAuthToken = () => {
	const token = localStorage.getItem('authToken');
	console.log("Retrieved auth token from localStorage:", token);
	return token;
};

export const saveListingData = async (data) => {
	try {
		localStorage.setItem("listingData", JSON.stringify(data));
	} catch (e) {
		console.log("Error saving listing data");
	}
};

export const clearListingData = () => {
	try {
		localStorage.removeItem("listingData");
	} catch (e) {
		console.log("an error occurred while removing listing data");
	}
};

export const getListingData = () => {
	try {
		return JSON.parse(localStorage.getItem("listingData"));
	} catch (e) {
		console.log("An error occurred while parsing string to json");
	}
};

export const getCurrency = () => {
	try {
		return JSON.parse(localStorage.getItem("userCurrency"));
	} catch (e) {
		console.log("An error occurred while parse string to json.");
	}
};


export const setCurrency = (data) => {
	try {
		localStorage.setItem("userCurrency", JSON.stringify(data));
	} catch (e) {
		console.log("An error occurred while parse string to json.");
	}
};

export const newProperty = () => {
	try {
		localStorage.getItem("newProperty");
		console.log(localStorage.getItem("newProperty"));
	} catch (e) {
		console.log("An error occurred while parse string to json.");
	}
};


export const setnewProperty = (data) => {
	try {
		console.log(data);
		localStorage.setItem("newProperty", data);
		console.log("Item set to true");
	} catch (e) {
		console.log("An error occurred while parse string to json.");
	}
};



export const setCompanyId = (data) => {
	try {
		localStorage.setItem("companyId", JSON.stringify(data));
	} catch (e) {
		console.log("An error occurred while parse string to json.");
	}
};

export const getCompanyId = () => {
	try {
		return JSON.parse(localStorage.getItem("companyId"));
	} catch (e) {
		console.log("An error occurred while parse string to json.");
	}
};

// confirm order response
export const setConfirmOrderResponse = (data) => {
	try {
		localStorage.setItem("confirmOrderResponse", JSON.stringify(data));
	} catch (e) {
		console.log("ERROR SAVING CONFIRM ORDER RESPONSE: ", e);
	}
};

export const getConfirmOrderResponse = () => {
	try {
		return JSON.parse(localStorage.getItem("confirmOrderResponse"));
	} catch (e) {
		console.log("ERROR GETTING CONFIRM ORDER RESPONSE: ", e);
	}
}

export const clearAllUserData = () => {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('authToken');
    localStorage.removeItem('listingData');
    localStorage.removeItem('userCurrency');
    localStorage.removeItem('newProperty');
    localStorage.removeItem('companyId');
    localStorage.removeItem('confirmOrderResponse');
    // Clear any cart related data
    localStorage.removeItem('cartDetails');
    console.log("Cleared all user data from localStorage");
};
