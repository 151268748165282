import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from '../utils/custom-hooks/usePagination';
import '../utils/custom-hooks/pagination.scss';

const Pagination = props => {
  const {
    onPageChange,
    totalCount = 0,
    siblingCount = 1,
    currentPage = 1,
    pageSize = 10,
    className
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  }) || [];

  // Return null if there's nothing to paginate
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    if (currentPage === paginationRange[paginationRange.length - 1]) return;
    onPageChange({ selected: currentPage + 1 });
  };

  const onPrevious = () => {
    if (currentPage === 1) return;
    onPageChange({ selected: currentPage - 1 });
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul
      className={classnames('pagination-container', { [className]: className })}
    >
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === 1
        })}
        onClick={onPrevious}
      >
        <div className="arrow left" />
      </li>
      {paginationRange.map((pageNumber, idx) => {
        if (pageNumber === DOTS) {
          return <li key={idx} className="pagination-item dots">&#8230;</li>;
        }

        return (
          <li
            key={idx}
            className={classnames('pagination-item', {
              selected: pageNumber === currentPage
            })}
            onClick={() => onPageChange({ selected: pageNumber })}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === lastPage
        })}
        onClick={onNext}
      >
        <div className="arrow right" />
      </li>
    </ul>
  );
};

export default Pagination;
